import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminPanelStructure from "../AdminPanelStructure";
import { handleDelete } from "../../utils/UtilsAdmin";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
const ExploreOverviewDetails = () => {
  const { examName } = useParams(); // Access dynamic URL parameters
 const navigate = useNavigate()
  const [formData, setFormData] = useState({
    // Overview
    importantDate: [],
    overViewHead: "",
    examName:"",
    ageLimit: [],
    vacancyDetails: [],
    eligibilityCriteria: [],
    applicationFee: [],
    importantLinks: [],
    categoryWiseVacancyDetails: [],
    loading: true,
    error: null,
  });

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('jwt');
        // Fetch both responses
        const response1 = await fetch(`${config.BASE_URL}/api/admin/explore/overview/${examName}`,{
          method:'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token
          },
        });

        if (!response1.ok) {
          throw new Error("Failed to fetch data");
        }

        const result1 = await response1.json();

        // Update state with fetched data
        setFormData((prevState) => ({
          ...prevState,
          overViewHead: result1.data.Overview.overViewHead,
          examName: result1.data.Overview.examName,
          importantDate: result1.data.Overview.importantDate?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
          })) || [],
          ageLimit: result1.data.Overview.ageLimit?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
          })) || [],
          vacancyDetails: result1.data.Overview.vacancyDetails?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
          })) || [],
          eligibilityCriteria: result1.data.Overview.eligibilityCriteria?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
          })) || [],
          applicationFee: result1.data.Overview.applicationFee?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
          })) || [],
          importantLinks: result1.data.Overview.importantLinks?.map((item) => ({
            colElement: item.colElement,
            colElementResult: item.colElementResult,
            resultLink: item.resultLink,
          })) || [],
          categoryWiseVacancyDetails: result1.data.Overview.categoryWiseVacancyDetails?.map((item) => ({
            postName: item.postName,
            ur: item.ur,
            sc: item.sc,
            st: item.st,
            obc: item.obc,
            ews: item.ews,
          })) || [],
          loading: false,
        }));
      } catch (err) {
        setFormData((prevState) => ({
          ...prevState,
          error: err.message,
          loading: false,
        }));
      }
    };

    fetchData();
  }, [examName]); // Depend on examName

  if (formData.loading) return <div>Loading...</div>;
  if (formData.error) return <div>Error: {formData.error}</div>;

  // Handle input changes
  const handleChange = (e, key, index = null, subKey = null) => {
    if (index !== null) {
      const updatedArray = [...formData[key]];
      updatedArray[index][subKey] = e.target.value;
      setFormData({ ...formData, [key]: updatedArray });
    } else {
      setFormData({ ...formData, [key]: e.target.value });
    }
  };
  

  const addRow = (key) => {
    setFormData({
      ...formData,
      [key]: [...formData[key], { colElemnt: "", colElementResult: "" }],
    });
    
  };
  const removeRow = (key) => {
    setFormData((prevData) => {
      const updatedRows = [...prevData[key]];
      updatedRows.pop(); // Remove the last element
      return {
        ...prevData,
        [key]: updatedRows,
      };
    });
  };

  const addCategoryRow = () => {
    setFormData({
      ...formData,
      categoryWiseVacancyDetails: [
        ...formData.categoryWiseVacancyDetails,
        { postName: "", ur: "", sc: "", st: "", obc: "", ews: "" },
      ],
    });
  };
  const removeCategoryRow = () => {
    setFormData((prevData) => {
      const updatedCategoryRows = [...prevData.categoryWiseVacancyDetails];
      updatedCategoryRows.pop(); // Remove the last element
      return {
        ...prevData,
        categoryWiseVacancyDetails: updatedCategoryRows,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try{
      const token = localStorage.getItem('jwt');
      const response = await fetch(`${config.BASE_URL}/api/admin/easy-navigaiton/${examName}`,{
        method:"PATCH",
        credentials:"include",
        headers: {
          "Authorization" : `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  

    }catch(err){
      console.error(err)
    }
  if (!formData) {
    alert('Please fill all required fields.');
    return;
  }

  try{
    const token = localStorage.getItem('jwt');
      const response = await fetch(`${config.BASE_URL}/api/admin/explore/overview/${examName}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token
        },
        body: JSON.stringify(formData),
        credentials: 'include',

      })

      if(!response.ok){
        throw new Error("Failed to Update data");
      }

     alert('Data submitted successfully!');

  }catch(err){
    alert('Error submitting data',err);
  }
};

  return (
    <>
      <AdminPanelStructure
        rightSidecontent={
          <form
            onSubmit={handleSubmit}
            className="mx-auto p-6 bg-white rounded-lg shadow-md space-y-4"
          >
           <div className="flex justify-between  pr-10"> <h2 className="text-2xl font-bold mb-4">Edit ExploreMain Schema Data </h2><button type="button" className="text-white bg-red-500 px-4 rounded-md shadow-lg text-sm hover:bg-red-600" onClick={()=>handleDelete(`${config.BASE_URL}/api/admin/explore/overview/${examName}`, '/admin/explore/overview',navigate)}>Delete Overview</button></div>
            {/* Overview Head */}
            <div className="flex flex-col space-y-2">
              <label className="text-md font-semibold">Overview Head:</label>
              <input
                type="text"
                value={formData.overViewHead}
                onChange={(e) => handleChange(e, "overViewHead")}
                required
                className="p-1 border border-gray-300 rounded-sm focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Important Dates */}
            <div>
              <h3 className="text-md font-semibold">Important Dates</h3>
              {formData.importantDate.map((date, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={date.colElement}
                    onChange={(e) =>
                      handleChange(e, "importantDate", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={date.colElementResult}
                    onChange={(e) =>
                      handleChange(e, "importantDate", index, "colElementResult")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("importantDate")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600 "
              >
                Add Important Date
              </button>
              <button
                type="button"
                onClick={() => removeRow("importantDate")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Important Date
              </button>
              </div>
            </div>

            {/* Age Limit */}
            <div>
              <h3 className="text-md font-semibold">Age Limit</h3>
              {formData.ageLimit.map((limit, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={limit.colElement}
                    onChange={(e) =>
                      handleChange(e, "ageLimit", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={limit.colElementResult}
                    onChange={(e) =>
                      handleChange(e, "ageLimit", index, "colElementResult")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("ageLimit")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Age Limit
              </button>
              <button
                type="button"
                onClick={() => removeRow("ageLimit")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Age Limit
              </button>
              </div>
            </div>

            {/* Eligibility Criteria */}
            <div>
              <h3 className="text-md font-semibold">Eligibility Criteria</h3>
              {formData.eligibilityCriteria.map((criteria, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={criteria.colElement}
                    onChange={(e) =>
                      handleChange(e, "eligibilityCriteria", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={criteria.colElementResult}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "eligibilityCriteria",
                        index,
                        "colElementResult"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("eligibilityCriteria")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Eligibility Criteria
              </button>
              <button
                type="button"
                onClick={() => removeRow("eligibilityCriteria")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Eligibility Criteria
              </button>
              </div>
            </div>

            {/* Vacancy Details */}
            <div>
              <h3 className="text-md font-semibold">Vacancy Details</h3>
              {formData.vacancyDetails.map((vacancy, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={vacancy.colElement}
                    onChange={(e) =>
                      handleChange(e, "vacancyDetails", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={vacancy.colElementResult}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "vacancyDetails",
                        index,
                        "colElementResult"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("vacancyDetails")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Vacancy Details
              </button>
              <button
                type="button"
                onClick={() => removeRow("vacancyDetails")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Vacancy Details
              </button>
              </div>
            </div>

            {/* Application Fee */}
            <div>
              <h3 className="text-md font-semibold">Application Fee</h3>
              {formData.applicationFee.map((fee, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={fee.colElement}
                    onChange={(e) =>
                      handleChange(e, "applicationFee", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={fee.colElementResult}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "applicationFee",
                        index,
                        "colElementResult"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("applicationFee")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Application Fee
              </button>
              <button
                type="button"
                onClick={() => removeRow("applicationFee")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Application Fee
              </button>
              </div>
            </div>

            {/* Important Links */}
            <div>
              <h3 className="text-md font-semibold">Important Links</h3>
              {formData.importantLinks.map((link, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="text"
                    placeholder="Col Element"
                    value={link.colElement}
                    onChange={(e) =>
                      handleChange(e, "importantLinks", index, "colElement")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Col Element Result"
                    value={link.colElementResult}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "importantLinks",
                        index,
                        "colElementResult"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="Result Link"
                    value={link.resultLink}
                    onChange={(e) =>
                      handleChange(e, "importantLinks", index, "resultLink")
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={() => addRow("importantLinks")}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Important Link
              </button>
              <button
                type="button"
                onClick={() => removeRow("importantLinks")}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Add Important Link
              </button>
              </div>
            </div>

            {/* Add Category-wise Vacancy */}
            <div>
              <h3 className="text-md font-semibold">Add Category-wise Vacancy</h3>
              {formData.categoryWiseVacancyDetails.map((vacancy, index) => (
                <div key={index} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    placeholder="Post Name"
                    value={vacancy.postName}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "postName"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm flex-1 focus:ring-2 focus:ring-blue-500 hover:bg-gray-50"
                  />
                  <input
                    type="text"
                    placeholder="UR"
                    value={vacancy.ur}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "ur"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm w-[17%] focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="SC"
                    value={vacancy.sc}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "sc"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm w-[17%] focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="ST"
                    value={vacancy.st}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "st"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm w-[17%] focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="OBC"
                    value={vacancy.obc}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "obc"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm w-[17%] focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="text"
                    placeholder="EWS"
                    value={vacancy.ews}
                    onChange={(e) =>
                      handleChange(
                        e,
                        "categoryWiseVacancyDetails",
                        index,
                        "ews"
                      )
                    }
                    required
                    className="p-1 border border-gray-300 rounded-sm w-[17%] focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              ))}
              <div className="flex gap-4">
              <button
                type="button"
                onClick={addCategoryRow}
                className="px-2 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-600"
              >
                Add Category Row
              </button>
              <button
                type="button"
                onClick={removeCategoryRow}
                className="px-2 py-1 bg-red-300 text-white rounded-sm hover:bg-red-400"
              >
                Remove Category Row
              </button>
              </div>
            </div>

            <div>
              <label className="block font-medium text-gray-700 mb-2">URL (immutable)</label>
              <input
                type="text"
                name="url"
                value={formData.examName}
                className='bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full'
                readOnly
              />
            </div>

            {/* Submit */}
            <button
              type="submit"
              className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Submit
            </button>
          </form>
        }
      />
    </>
  );
};

export default ExploreOverviewDetails;
