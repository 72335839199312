export const StandUpIndiaData = {
  title: "Stand-Up India",
  schemeLink: "/scheme-entrepreneurship-startup/stand-up-india",
  description: `
    **Stand-Up India** is a scheme that aims to facilitate bank loans between ₹10 lakh and ₹1 crore to at least one Scheduled Caste (SC) or Scheduled Tribe (ST) borrower and at least one woman borrower per bank branch for setting up greenfield enterprises.
    
    **Key Objectives**:
    - Promote entrepreneurship among SC/ST and women
    - Enable economic participation of SC/ST and women entrepreneurs
    - Facilitate credit flow for startups and greenfield enterprises
  `,
  applyLink: "https://www.standupmitra.in",
  stepsToApply: [
    "Register on the Stand-Up India portal",
    "Submit business proposal/plan",
    "Upload required documents",
    "Select preferred bank branch",
    "Track application status online"
  ],
  eligibility: [
    "SC/ST and/or woman entrepreneurs",
    "Above 18 years of age",
    "For greenfield projects only",
    "Borrower should not be in default to any bank/financial institution"
  ],
  benefits: [
    "Composite loan between ₹10 lakh and ₹1 crore",
    "Coverage of both manufacturing and services sector",
    "Training and support for project preparation",
    "Handholding support",
    "No collateral required for loans up to ₹10 lakhs"
  ],
  requirements: [
    "Identity proof",
    "Address proof",
    "Caste certificate (for SC/ST)",
    "Business plan",
    "Project report"
  ],
  faqs: [
    {
      question: "What is the maximum loan amount under Stand-Up India?",
      answer: "The maximum loan amount is ₹1 crore."
    },
    {
      question: "Is collateral security mandatory?",
      answer: "No collateral is required for loans up to ₹10 lakhs under CGTMSE coverage."
    },
    {
      question: "Can a non-SC/ST individual apply for Stand-Up India?",
      answer: "No, only SC/ST and woman entrepreneurs are eligible for this scheme."
    },
    {
      question: "What happens if I default on my loan?",
      answer: "In case of default, the applicant will not be eligible for future loans under Stand-Up India."
    }
  ],
  moreAboutThisScheme: `
    Stand-Up India has been instrumental in promoting entrepreneurship among SC/ST and women entrepreneurs. The scheme provides comprehensive support including financial assistance and mentorship.
  `
};

export const MakeInIndiaData = {
  title: "Make in India",
  schemeLink: "/scheme-entrepreneurship-startup/make-in-india",
  description: `
    **Make in India** is a major national program designed to facilitate investment, foster innovation, enhance skill development, protect intellectual property, and build best-in-class manufacturing infrastructure.
  `,
  applyLink: "https://www.makeinindia.com",
  stepsToApply: [
    "Visit Make in India website",
    "Select relevant sector",
    "Submit investment proposal",
    "Complete registration process",
    "Access sector-specific incentives"
  ],
  eligibility: [
    "Both Indian and foreign companies",
    "Manufacturing sector enterprises",
    "Service sector companies",
    "New and existing businesses"
  ],
  benefits: [
    "Fast-track investment proposals",
    "Dedicated investor facilitation cell",
    "Ease of doing business initiatives",
    "Sector-specific incentives",
    "Infrastructure support"
  ],
  requirements: [
    "Business registration documents",
    "Investment proposal",
    "Sector-specific compliance documents",
    "Environmental clearances (if applicable)"
  ],
  faqs: [
    {
      question: "Which sectors are covered under Make in India?",
      answer: "25 sectors including automobiles, IT, pharmaceuticals, textiles, and more."
    },
    {
      question: "Are foreign companies eligible?",
      answer: "Yes, both Indian and foreign companies can participate in the Make in India initiative."
    },
    {
      question: "How long does it take for the proposal to be reviewed?",
      answer: "The review process typically takes 1-2 months, depending on the complexity of the proposal."
    },
    {
      question: "Do I need to be an Indian citizen to apply?",
      answer: "No, foreign companies and entrepreneurs can also apply."
    }
  ],
  moreAboutThisScheme: `
    Make in India has transformed India into a global manufacturing hub. The initiative has successfully attracted investments across sectors and created numerous employment opportunities.
  `
};

export const MudraYojanaData = {
  title: "Mudra Yojana",
  schemeLink: "/scheme-entrepreneurship-startup/mudra-yojana",
  description: `
    **Pradhan Mantri Mudra Yojana (PMMY)** provides loans up to ₹10 lakh to non-corporate, non-farm small/micro enterprises. These loans are given by Commercial Banks, RRBs, Small Finance Banks, MFIs and NBFCs.
  `,
  applyLink: "https://www.mudra.org.in",
  stepsToApply: [
    "Visit nearest bank branch",
    "Submit Mudra loan application",
    "Provide business proposal",
    "Submit required documents",
    "Complete verification process"
  ],
  eligibility: [
    "Small business owners",
    "Individuals starting new business",
    "Existing small businesses",
    "Non-corporate small business entities"
  ],
  benefits: [
    "Shishu: Loans up to ₹50,000",
    "Kishore: Loans up to ₹5 lakh",
    "Tarun: Loans up to ₹10 lakh",
    "No collateral required",
    "Lower interest rates"
  ],
  requirements: [
    "Identity proof",
    "Address proof",
    "Business plan",
    "Quotations of machines/items to be purchased",
    "Previous business details (if any)"
  ],
  faqs: [
    {
      question: "What are the different categories of Mudra loans?",
      answer: "There are three categories: Shishu (up to ₹50,000), Kishore (up to ₹5 lakh), and Tarun (up to ₹10 lakh)."
    },
    {
      question: "Is collateral required for Mudra loan?",
      answer: "No collateral is required for loans under Mudra Yojana."
    },
    {
      question: "How long does the loan disbursal take?",
      answer: "The loan disbursal usually takes 7-10 business days after the verification process."
    },
    {
      question: "Can I apply for Mudra loan online?",
      answer: "Yes, Mudra loans can be applied for online through the Mudra portal."
    }
  ],
  moreAboutThisScheme: `
    PMMY has been crucial in funding the unfunded and promoting entrepreneurship at the grassroots level. The scheme has particularly benefited women entrepreneurs and small business owners.
  `
};

export const DigitalIndiaData = {
  title: "Digital India",
  schemeLink: "/scheme-entrepreneurship-startup/digital-india",
  description: `
    **Digital India** is a flagship programme aimed at transforming India into a digitally empowered society and knowledge economy. It focuses on digital infrastructure, governance, and services.
  `,
  applyLink: "https://digitalindia.gov.in",
  stepsToApply: [
    "Visit Digital India portal",
    "Select relevant digital service",
    "Register for digital initiatives",
    "Access digital resources",
    "Participate in skill development programs"
  ],
  eligibility: [
    "All Indian citizens",
    "Technology startups",
    "Digital service providers",
    "Educational institutions"
  ],
  benefits: [
    "Digital infrastructure access",
    "E-governance services",
    "Digital skill development",
    "Technology adoption support",
    "Innovation promotion"
  ],
  requirements: [
    "Digital literacy",
    "Internet connectivity",
    "Basic computer knowledge",
    "Digital device access"
  ],
  faqs: [
    {
      question: "What services are available under Digital India?",
      answer: "Services include digital payments, e-governance, skill development, and digital infrastructure."
    },
    {
      question: "How can startups benefit from Digital India?",
      answer: "Startups can access digital infrastructure, mentorship, and market access opportunities."
    },
    {
      question: "What is the Digital India program's main goal?",
      answer: "The main goal is to enhance online infrastructure and increase internet connectivity, aiming for a digitally empowered society."
    },
    {
      question: "Can foreign companies participate in Digital India?",
      answer: "Yes, international companies can participate in digital infrastructure development and digital services."
    }
  ],
  moreAboutThisScheme: `
    Digital India has revolutionized how citizens interact with technology and access government services. The initiative has created numerous opportunities for digital entrepreneurs and innovators.
  `
};

export const AtalInnovationData = {
  title: "Atal Innovation Mission",
  schemeLink: "/scheme-entrepreneurship-startup/atal-innovation",
  description: `
    **Atal Innovation Mission (AIM)** is Government of India's flagship initiative to promote innovation and entrepreneurship across the country. It focuses on creating and promoting an ecosystem of innovation and entrepreneurship across various sectors.
  `,
  applyLink: "https://aim.gov.in",
  stepsToApply: [
    "Visit AIM portal",
    "Select relevant program",
    "Submit proposal or application",
    "Provide necessary documents",
    "Engage in mentorship sessions"
  ],
  eligibility: [
    "Educational institutions",
    "Innovation hubs",
    "Research and development organizations",
    "Startups"
  ],
  benefits: [
    "Access to seed funding",
    "Entrepreneurship education and mentorship",
    "Networking opportunities",
    "Incubation support",
    "Access to government programs"
  ],
  requirements: [
    "Detailed project proposal",
    "Business plan",
    "Proof of concept",
    "Support from incubators or accelerators"
  ],
  faqs: [
    {
      question: "What kind of startups are eligible for AIM?",
      answer: "AIM supports startups in various sectors such as technology, healthcare"
    },
    {
      question: "Is there any support for women entrepreneurs?",
      answer: "Yes, AIM provides special programs and funding support for women entrepreneurs."
    },
    {
      question: "Can international startups participate in AIM?",
      answer: "Atal Innovation Mission primarily supports Indian startups, but international collaboration can be explored through various partnerships."
    }
  ],
  moreAboutThisScheme: `
    The Atal Innovation Mission fosters innovation and promotes entrepreneurship by providing financial assistance, incubation centers, and skill development programs. It plays a key role in bridging the gap between academia, industries, and entrepreneurs.
  `
};
