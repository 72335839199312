import React from 'react';
import { FaChevronDown, FaChevronRight, FaCheckCircle, FaRegQuestionCircle, FaFileAlt, FaExternalLinkAlt, FaGraduationCap } from 'react-icons/fa';
import { IoMdSchool } from 'react-icons/io';
import SchemeDisclaimer from '../SchemeDisclaimer';


const SchemeDetail = ({ data }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-blue-50 to-indigo-50 py-8 px-2 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Header Section */}
        <div className="relative bg-gradient-to-r from-emerald-600 to-lime-700 px-6 py-8 sm:px-10 sm:py-12">
          <div className="absolute inset-0 bg-grid-white/[0.1] bg-[size:16px_16px]"></div>
          <h1 className="text-3xl sm:text-4xl font-bold text-white text-center relative z-10 mb-4 flex flex-col">
            <p className='flex item-center justify-center p-4'><FaGraduationCap className="text-6xl" /></p>
            {data.title}
          </h1>
          <div className="text-emerald-50 text-center max-w-2xl mx-auto text-lg" 
               dangerouslySetInnerHTML={{ __html: data.description.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }}>
          </div>
        </div>

        <div className="p-3 sm:p-8">
          {/* Quick Actions */}
          <div className="flex flex-wrap gap-4 mb-8 justify-center">
            <a
              href={data.applyLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-emerald-600 text-white rounded-lg shadow-lg hover:bg-emerald-700 transition-all duration-300 transform hover:scale-105"
            >
              <FaFileAlt className="w-5 h-5 mr-2" />
              Apply Now
            </a>
            <a
              href={data.schemeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
            >
              <FaExternalLinkAlt className="w-5 h-5 mr-2" />
              Learn More
            </a>
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            {/* Steps to Apply */}
            <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-emerald-200 transition-colors">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <FaChevronRight className="w-6 h-6 text-emerald-600 mr-2" />
                Steps to Apply
              </h2>
              <ol className="space-y-4">
                {data.stepsToApply.map((step, index) => (
                  <li key={index} className="flex items-start space-x-3 group">
                    <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-emerald-100 text-emerald-600 font-semibold group-hover:bg-emerald-600 group-hover:text-white transition-colors">
                      {index + 1}
                    </span>
                    <span className="text-gray-700 group-hover:text-gray-900 transition-colors">
                      {step}
                    </span>
                  </li>
                ))}
              </ol>
            </section>

            {/* Eligibility & Benefits Grid */}
            <div className="grid md:grid-cols-2 gap-6">
              {/* Eligibility */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-blue-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <FaCheckCircle className="w-6 h-6 text-blue-600 mr-2" />
                  Eligibility
                </h2>
                <ul className="space-y-3">
                  {data.eligibility.map((criteria, index) => (
                    <li key={index} className="flex items-center space-x-3 group">
                      <FaChevronRight className="w-5 h-5 text-blue-500 group-hover:text-blue-600 transition-colors" />
                      <span className="text-gray-700 group-hover:text-gray-900 transition-colors">
                        {criteria}
                      </span>
                    </li>
                  ))}
                </ul>
              </section>

              {/* Benefits */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-indigo-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <FaCheckCircle className="w-6 h-6 text-indigo-600 mr-2" />
                  Benefits
                </h2>
                <div className="space-y-3">
                  {data.benefits.map((benefit, index) => (
                    <div
                      key={index}
                      className="p-3 bg-indigo-50 rounded-lg text-gray-700 hover:bg-indigo-100 transition-colors"
                    >
                      {benefit}
                    </div>
                  ))}
                </div>
              </section>
            </div>

            {/* Requirements & Additional Info */}
            <div className="grid md:grid-cols-2 gap-6">
              {/* Requirements */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-purple-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <FaRegQuestionCircle className="w-6 h-6 text-purple-600 mr-2" />
                  Requirements
                </h2>
                <ul className="space-y-2">
                  {data.requirements.map((requirement, index) => (
                    <li key={index} className="flex items-center space-x-3 group">
                      <FaCheckCircle className="w-5 h-5 text-purple-500 group-hover:text-purple-600 transition-colors" />
                      <span className="text-gray-700 group-hover:text-gray-900 transition-colors">
                        {requirement}
                      </span>
                    </li>
                  ))}
                </ul>
              </section>

              {/* Additional Details */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-red-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <IoMdSchool className="w-6 h-6 text-red-600 mr-2" />
                  Additional Details
                </h2>
                <ul className="space-y-2">
                  {data.additionalDetails && data.additionalDetails.map((detail, index) => (
                    <li key={index} className="flex items-center space-x-3 group">
                      <FaChevronRight className="w-5 h-5 text-red-500 group-hover:text-red-600 transition-colors" />
                      <span className="text-gray-700 group-hover:text-gray-900 transition-colors">
                        {detail}
                      </span>
                    </li>
                  ))}
                </ul>
              </section>
            </div>

            {/* FAQs */}
            <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
              <h2 className="text-2xl font-bold text-gray-800 mb-6 flex items-center">
                <FaRegQuestionCircle className="w-6 h-6 text-orange-600 mr-2" />
                Frequently Asked Questions
              </h2>
              <div className="space-y-4">
                {data.faqs.map((faq, index) => (
                  <details key={index} className="group bg-orange-50 rounded-lg transition-all duration-300">
                    <summary className="flex items-center justify-between cursor-pointer p-4 font-medium text-gray-800">
                      {faq.question}
                      <FaChevronDown className="w-5 h-5 text-orange-600 transform group-open:rotate-180 transition-transform" />
                    </summary>
                    <div className="px-4 pb-4 text-gray-700">{faq.answer}</div>
                  </details>
                ))}
              </div>
            </section>
            <SchemeDisclaimer/>

            {/* More Info */}
            <section className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">More Information</h2>
              <div className="text-gray-700"
                   dangerouslySetInnerHTML={{ __html: data.moreAboutThisScheme.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }}>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};


export default SchemeDetail;