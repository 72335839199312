import React from "react";
import CategoryCard from "./CategoryCard";
import { FaUserTie, FaGraduationCap, FaHeartbeat, FaSeedling, FaHome, FaChild, FaPiggyBank, FaLightbulb, FaLeaf ,FaExternalLinkAlt } from "react-icons/fa";

const SchemeMain = () => {
  const categories = [
    {
      icon: <FaExternalLinkAlt />,
      id: 1,
      title: "All Scheme of the government",
      schemesCount: 5,
      description: "Explore all government initiatives and schemes.",
      link: "/scheme-categories/all",
    },
    {
      icon: <FaUserTie />,
      id: 1,
      title: "Employment and Skill Development",
      schemesCount: 5,
      description: "Explore government initiatives for jobs and skill training.",
      link: "/scheme-categories/employment-skill-development",
    },
    {
      icon: <FaGraduationCap />,
      id: 2,
      title: "Education and Scholarships",
      schemesCount: 5,
      description: "Discover scholarships and educational programs for students.",
      link: "/scheme-categories/education-scholarships",
    },
    {
      icon: <FaLightbulb />,
      id: 8,
      title: "Entrepreneurship and Startups",
      schemesCount: 5,
      description: "Explore Entrepreneurship and Startups initiatives.",
      link: "/scheme-categories/startups-entrepreneurship",
    },
    {
      icon: <FaHeartbeat />,
      id: 3,
      title: "Health and Well-Being",
      schemesCount: 5,
      description: "Learn about healthcare and insurance schemes.",
      link: "/scheme-categories/health-well-being",
    },
    {
      icon: <FaSeedling />,
      id: 4,
      title: "Agriculture and Rural Development",
      schemesCount: 5,
      description: "Find support for farmers and rural infrastructure projects.",
      link: "/scheme-categories/agriculture-rural-development",
    },
    {
      icon: <FaChild />,
      id: 5,
      title: "Women and Child Welfare",
      schemesCount: 5,
      description: "Access schemes promoting welfare for women and children.",
      link: "/scheme-categories/women-child-welfare",
    },
    {
      icon: <FaHome />,
      id: 6,
      title: "Housing and Urban Development",
      schemesCount: 5,
      description: "Browse schemes for affordable housing and urban development.",
      link: "/scheme-categories/housing-and-urban-development",
    },
    {
      icon: <FaPiggyBank />,
      id: 7,
      title: "Financial Inclusion and Support",
      schemesCount: 5,
      description: "Explore financial aid and banking access initiatives.",
      link: "/scheme-categories/finance-aid",
    },
    {
      icon: <FaLeaf />,
      id: 9,
      title: "Environment and Energy",
      schemesCount: 5,
      description: "Learn about renewable energy and environmental conservation.",
      link: "/scheme-categories/environment-and-energy",
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl md:text-3xl font-semibold font-serif text-purple-800 py-4 pt-8 text-center">
        Explore Government Schemes and Initiatives
      </h1>
      <div className="categories-grid grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-5 py-4 md:w-[85vw] ">
        {categories.map((category, index) => (
          <CategoryCard
            key={index}
            title={category.title}
            description={category.description}
            schemeCount={category.schemesCount}
            link={category.link}
            icon={category.icon}
          />
        ))}
      </div>
    </div>
  );
  
};

export default SchemeMain;
