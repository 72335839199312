import React, { useState, useEffect } from 'react';
import TextSlider from './home-components/slider/TextSlider';
import EasyNavigationButton from './home-components/EasyNavigationButton';
import ExploreList from './home-components/explore/ExploreList';
import SarkariYojanas from './home-components/SarkariYojanas';

const Home = () => {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 800); // Set the spinner animation duration

    return () => clearTimeout(timer);
  }, []);
  

  return (
    <div className="relative">
      {/* Spinner - Displayed during the initial load */}
      {isAnimating && (
        <div className="fixed inset-0 bg-white bg-opacity-80 z-50 flex items-center justify-center">
          <div className="relative">
            <div className="w-16 h-16 border-4 border-blue-200 border-solid rounded-full animate-spin">
              <div className="absolute top-0 left-0 w-16 h-16 border-4 border-blue-600 border-t-4 rounded-full animate-spin"></div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content - Will display after animation */}
      <div className={isAnimating ? 'opacity-0' : 'opacity-100 transition-opacity'}>
        <TextSlider />
        <EasyNavigationButton />
        <ExploreList />
        <SarkariYojanas />

        {/* Informational Section */}
        <div className="m-4 p-4 sm:p-6 border rounded-xl shadow-lg bg-white text-gray-800 text-base sm:text-lg leading-relaxed">
          <h2 className="bg-gradient-to-r from-blue-600 to-green-500 text-center p-3 font-bold text-xl sm:text-2xl text-white rounded-lg shadow-md">
            How We Are Different from Others
          </h2>
          <div className="p-4 sm:p-6 text-sm sm:text-md bg-green-50 rounded-lg mt-4">
            <p className="mb-4">
              At <strong>SarkariLock.in</strong>, we elevate government job preparation with a platform designed for your success. Unlike typical sites that offer only basic updates like exam results and admit cards, we provide real-time, accurate information to keep you ahead. Our comprehensive approach includes expertly crafted preparation guides and strategies to ensure you're confident and well-prepared for your exams.
            </p>

            <p className="mt-4">
              In addition to exam resources, we offer detailed coverage of government schemes (<strong>Sarkari-Yojanas</strong>), giving you valuable insights into various initiatives and benefits. Our goal is to make your preparation journey seamless by providing all the necessary resources and exclusive content in one place. At <strong>SarkariLock.in</strong>, you're not just accessing information; you're joining a community dedicated to your growth and success in the government sector.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
