export const JanDhanYojanaData = {
  title: "Pradhan Mantri Jan Dhan Yojana",
  schemeLink: "/scheme-financial/jan-dhan-yojana",
  description: `
    **Pradhan Mantri Jan Dhan Yojana (PMJDY)** is a National Mission for Financial Inclusion to ensure access to financial services, namely, banking, savings & deposit accounts, remittance, credit, insurance, pension in an affordable manner.

    **Key Objectives**:
    - Universal access to banking facilities
    - Financial literacy
    - Access to credit, insurance and pension facilities
    - Creation of awareness about financial products
  `,
  applyLink: "https://www.pmjdy.gov.in",
  stepsToApply: [
    "Visit nearest bank branch",
    "Fill account opening form",
    "Submit KYC documents",
    "Receive RuPay debit card",
    "Start banking services"
  ],
  eligibility: [
    "Any Indian citizen above 10 years",
    "Zero balance facility",
    "No minimum balance required",
    "One account per household"
  ],
  benefits: [
    "Zero balance savings account",
    "RuPay debit card with insurance",
    "Accident insurance cover",
    "Overdraft facility",
    "Direct Benefit Transfer channel"
  ],
  requirements: [
    "Identity proof",
    "Address proof",
    "Recent photograph",
    "Mobile number",
    "Aadhaar card (preferred)"
  ],
  faqs: [
    {
      question: "Is there any minimum balance requirement?",
      answer: "No, PMJDY accounts can be opened with zero balance."
    },
    {
      question: "What insurance benefits are provided?",
      answer: "Account holders get accidental insurance cover of ₹2 lakhs and life insurance of ₹30,000."
    }
  ],
  moreAboutThisScheme: `
    PMJDY has been a game-changer in promoting financial inclusion and providing banking services to the unbanked population of India.
  `
};

export const DirectBenefitTransferData = {
  title: "Direct Benefit Transfer",
  schemeLink: "/scheme-financial/dbt-direct-benefit-transfer",
  description: `
    **Direct Benefit Transfer (DBT)** aims to transfer subsidies directly to people through their bank accounts. It helps in ensuring faster, more accurate targeting of beneficiaries and reduction of fraud.
  `,
  applyLink: "https://dbtbharat.gov.in",
  stepsToApply: [
    "Link Aadhaar with bank account",
    "Register for specific schemes",
    "Submit required documents",
    "Verify bank account details",
    "Receive direct transfers"
  ],
  eligibility: [
    "Beneficiaries of government schemes",
    "Valid bank account holders",
    "Aadhaar card holders",
    "Scheme-specific eligibility"
  ],
  benefits: [
    "Direct transfer to bank account",
    "Elimination of middlemen",
    "Reduced leakages",
    "Faster benefit delivery",
    "Better targeting of beneficiaries"
  ],
  requirements: [
    "Aadhaar card",
    "Bank account",
    "Mobile number",
    "Scheme-specific documents",
    "Valid identification"
  ],
  faqs: [
    {
      question: "How long does it take to receive benefits?",
      answer: "Once approved, benefits are typically transferred within 24-48 hours."
    },
    {
      question: "Is Aadhaar mandatory for DBT?",
      answer: "Yes, Aadhaar is mandatory for most DBT schemes to prevent duplication."
    }
  ],
  moreAboutThisScheme: `
    DBT has revolutionized the delivery of government benefits by ensuring transparent and efficient transfer of subsidies directly to beneficiaries.
  `
};

export const AtalPensionYojanaData = {
  title: "Atal Pension Yojana",
  schemeLink: "/scheme-financial/atal-pension-yojana",
  description: `
    **Atal Pension Yojana (APY)** is a government-backed pension scheme primarily aimed at workers in the unorganized sector. It provides a defined pension based on the contribution amount and period.
  `,
  applyLink: "https://www.npscra.nsdl.co.in/scheme-details.php",
  stepsToApply: [
    "Visit bank branch or online banking",
    "Fill APY registration form",
    "Choose pension amount",
    "Set up auto-debit",
    "Receive acknowledgment"
  ],
  eligibility: [
    "Age between 18-40 years",
    "Bank account holders",
    "Not covered by any statutory social security scheme",
    "Valid KYC documents"
  ],
  benefits: [
    "Guaranteed pension after 60 years",
    "Government co-contribution",
    "Tax benefits under 80CCD",
    "Option for spouse continuation",
    "Return of corpus to nominee"
  ],
  requirements: [
    "Bank account",
    "Aadhaar card",
    "Mobile number",
    "Age proof",
    "KYC documents"
  ],
  faqs: [
    {
      question: "What are the pension amount options?",
      answer: "Monthly pension options range from ₹1,000 to ₹5,000 per month."
    },
    {
      question: "When does the pension start?",
      answer: "The pension starts after the subscriber reaches 60 years of age."
    }
  ],
  moreAboutThisScheme: `
    APY provides social security to workers in the unorganized sector by ensuring a guaranteed pension after retirement.
  `
};

export const PMVayaVandanaData = {
  title: "Pradhan Mantri Vaya Vandana Yojana",
  schemeLink: "/scheme-financial/pm-vaya-vandana",
  description: `
    **Pradhan Mantri Vaya Vandana Yojana (PMVVY)** is a pension scheme for senior citizens aged 60 years and above. It provides assured pension based on a guaranteed rate of return.
  `,
  applyLink: "https://licindia.in/Products/Pension-Plans",
  stepsToApply: [
    "Visit LIC office or website",
    "Fill application form",
    "Submit required documents",
    "Make lump sum payment",
    "Receive policy document"
  ],
  eligibility: [
    "Senior citizens aged 60 years and above",
    "Indian residents",
    "Valid bank account",
    "KYC compliant"
  ],
  benefits: [
    "Guaranteed pension for 10 years",
    "Monthly/quarterly/yearly payout options",
    "Loan facility available",
    "Return of purchase price to nominee",
    "Tax benefits"
  ],
  requirements: [
    "Age proof",
    "Identity proof",
    "Address proof",
    "Bank account details",
    "Recent photographs"
  ],
  faqs: [
    {
      question: "What is the minimum investment amount?",
      answer: "The minimum investment amount is ₹1,56,658 for a pension of ₹1,000 per month."
    },
    {
      question: "Can I exit prematurely?",
      answer: "Premature exit is allowed in exceptional circumstances with 98% of purchase price."
    }
  ],
  moreAboutThisScheme: `
    PMVVY provides financial security to senior citizens through guaranteed pension payments and protection against interest rate fluctuations.
  `
};

export const GoldMonetizationData = {
  title: "Gold Monetization Scheme",
  schemeLink: "/scheme-financial/gold-monetization",
  description: `
    The **Gold Monetization Scheme (GMS)** aims to mobilize gold held by households and institutions in the country and facilitate its use for productive purposes, while providing interest on the gold deposited.
  `,
  applyLink: "https://finmin.nic.in/gold-monetization-scheme",
  stepsToApply: [
    "Visit participating bank",
    "Get gold purity tested",
    "Open gold savings account",
    "Deposit gold",
    "Earn interest on deposits"
  ],
  eligibility: [
    "Residents of India",
    "Individuals and institutions",
    "HUFs and trusts",
    "Joint depositors"
  ],
  benefits: [
    "Interest on gold deposits",
    "Secure storage of gold",
    "No storage and insurance costs",
    "Tax benefits",
    "Option for gold or cash returns"
  ],
  requirements: [
    "KYC documents",
    "Gold items for deposit",
    "Bank account",
    "PAN card",
    "Address proof"
  ],
  faqs: [
    {
      question: "What is the minimum deposit?",
      answer: "Minimum deposit is 30 grams of gold (999 purity)."
    },
    {
      question: "What interest rates are offered?",
      answer: "Interest rates vary from 2.25% to 2.50% based on deposit tenure."
    }
  ],
  moreAboutThisScheme: `
    The Gold Monetization Scheme helps in mobilizing idle gold in the country while providing returns to depositors, contributing to the economy's growth.
  `
};