export const PMKISANData = {
  title: "PM-KISAN",
  schemeLink: "/scheme-agriculture-and-rural-development/pm-kisan",
  description: `
    **Pradhan Mantri Kisan Samman Nidhi (PM-KISAN)** is a Central Sector scheme that provides income support to all landholding farmers' families in the country. The scheme aims to supplement the financial needs of farmers for procuring various inputs related to agriculture and allied activities as well as domestic needs.

    **Key Objectives**:
    - Provide assured supplemental income to farmers
    - Enable farmers to meet agricultural expenses and domestic needs
    - Protect farmers from falling into debt
  `,
  applyLink: "https://pmkisan.gov.in",
  stepsToApply: [
    "Visit the PM-KISAN portal or Common Service Center",
    "Fill the registration form with personal and land details",
    "Submit required documents including land records",
    "Verify Aadhaar and bank account details",
    "Track application status online"
  ],
  eligibility: [
    "All landholding farmer families",
    "Small and marginal farmers",
    "Subject to certain exclusion criteria",
    "Must have cultivable land as per land records"
  ],
  benefits: [
    "₹6,000 per year in three equal installments",
    "Direct Benefit Transfer to bank accounts",
    "No repayment obligation",
    "Additional state-level benefits in some cases",
    "Insurance coverage options"
  ],
  requirements: [
    "Aadhaar card",
    "Land records",
    "Bank account details",
    "Mobile number",
    "Recent photograph"
  ],
  faqs:[
    {
      question: "How is the payment made under PM-KISAN?",
      answer: "The payment of ₹6,000 per year is made in three equal installments of ₹2,000 each, directly to the beneficiary's bank account."
    },
    {
      question: "Can landless farmers apply?",
      answer: "No, only farmers with landholding records are eligible under the scheme."
    },
    {
      question: "What happens if a beneficiary dies?",
      answer: "In case of death of the beneficiary, the amount will be transferred to the nominee's account after proper verification."
    },
    {
      question: "Is there an upper limit for land holding?",
      answer: "No, there is no upper limit for land holding. However, certain exclusion criteria apply for high-income farmers."
    },
    {
      question: "Can NRI farmers get benefits under PM-KISAN?",
      answer: "No, NRI farmers are not eligible under the PM-KISAN scheme."
    },
    {
      question: "How can I check my payment status?",
      answer: "You can check your payment status on the PM-KISAN portal using your Aadhaar number or account number."
    }
  ],
  moreAboutThisScheme: `
    PM-KISAN has emerged as a crucial support system for farmers across India, helping them meet both agricultural and domestic needs. The scheme's direct benefit transfer ensures transparent and efficient delivery of financial assistance.
  `
};

export const SoilHealthCardData = {
  title: "Soil Health Card Scheme",
  schemeLink: "/scheme-agriculture-and-rural-development/soil-health-card",
  description: `
    The **Soil Health Card Scheme** is a flagship program that assesses the soil condition and provides farmers with nutrient recommendations for improved productivity. It aims to promote soil test-based nutrient management.
  `,
  applyLink: "https://soilhealth.dac.gov.in",
  stepsToApply: [
    "Visit local agriculture office or Krishi Vigyan Kendra",
    "Submit soil sample request",
    "Get soil testing done",
    "Receive Soil Health Card",
    "Follow recommended practices"
  ],
  eligibility: [
    "All farmers with agricultural land",
    "Both landowners and tenants",
    "Individual and collective farming",
    "Priority to small and marginal farmers"
  ],
  benefits: [
    "Free soil testing facility",
    "Detailed nutrient status report",
    "Crop-wise recommendations",
    "Reduced input costs",
    "Improved soil health and productivity"
  ],
  requirements: [
    "Land details",
    "Farmer identification",
    "Soil samples",
    "Crop details",
    "Contact information"
  ],
  faqs: [
    {
      question: "How often should soil testing be done?",
      answer: "Soil testing is recommended once every three years."
    },
    {
      question: "Is there any cost for soil testing?",
      answer: "No, soil testing under this scheme is provided free of cost to farmers."
    },
    {
      question: "What parameters are tested in soil health card?",
      answer: "The card tests for macro nutrients (N,P,K), secondary nutrients (S), micronutrients (Zn, Fe, Cu, Mn, Bo), and physical parameters like pH and organic carbon."
    },
    {
      question: "How long does it take to get the soil health card?",
      answer: "It typically takes 2-3 weeks after soil sample submission to receive the soil health card."
    },
    {
      question: "Can I get multiple soil tests for different plots?",
      answer: "Yes, you can get separate soil tests for different plots with distinct soil characteristics."
    },
    {
      question: "Are the recommendations crop-specific?",
      answer: "Yes, the fertilizer recommendations are provided based on the specific crops you plan to grow."
    }
  ],
  moreAboutThisScheme: `
    The Soil Health Card Scheme has revolutionized farming practices by promoting scientific soil management. It helps farmers optimize their input use and improve crop yields sustainably.
  `
};

export const KisanCreditCardData = {
  title: "Kisan Credit Card Scheme",
  schemeLink: "/scheme-agriculture-and-rural-development/kisan-credit-card",
  description: `
    The **Kisan Credit Card (KCC) Scheme** provides farmers with timely access to credit for their agricultural needs. It offers a flexible and simplified procedure for obtaining credit support.
  `,
  applyLink: "https://www.pmkisan.gov.in/kcc",
  stepsToApply: [
    "Visit nearest bank branch",
    "Submit KCC application form",
    "Provide required documents",
    "Complete verification process",
    "Receive Kisan Credit Card"
  ],
  eligibility: [
    "Farmers - individual/joint cultivators",
    "Tenant farmers",
    "Oral lessees",
    "Share croppers",
    "Self Help Groups of farmers"
  ],
  benefits: [
    "Short-term credit for crops",
    "Cash credit facility",
    "Insurance coverage",
    "Flexible repayment options",
    "Reduced interest burden"
  ],
  requirements: [
    "Land records/tenancy documents",
    "Identity proof",
    "Address proof",
    "Passport size photographs",
    "Bank account details"
  ],
  faqs:  [
    {
      question: "What is the credit limit under KCC?",
      answer: "Credit limit is based on the scale of finance for crops and farmer's land holding."
    },
    {
      question: "Is insurance mandatory with KCC?",
      answer: "Yes, both asset and personal accident insurance are mandatory under KCC."
    },
    {
      question: "What is the interest rate on KCC?",
      answer: "Interest rate is 7% per annum, with additional 3% interest subvention for timely repayment."
    },
    {
      question: "Can I withdraw cash using KCC?",
      answer: "Yes, KCC can be used for cash withdrawals from ATMs and bank branches."
    },
    {
      question: "What is the renewal period of KCC?",
      answer: "KCC needs to be renewed every three years after proper assessment."
    },
    {
      question: "Can I use KCC for non-agricultural purposes?",
      answer: "No, KCC is specifically for agriculture and allied activities only."
    }
  ],
  moreAboutThisScheme: `
    The Kisan Credit Card scheme has significantly improved farmers' access to formal credit, reducing their dependence on informal lending sources and supporting timely agricultural operations.
  `
};

export const NationalRuralLivelihoodData = {
  title: "National Rural Livelihood Mission",
  schemeLink: "/scheme-agriculture-and-rural-development/nrlm-national-rural-livelihood",
  description: `
    The **National Rural Livelihood Mission (NRLM)** aims to reduce poverty by enabling poor households to access gainful self-employment and skilled wage employment opportunities.
  `,
  applyLink: "https://aajeevika.gov.in",
  stepsToApply: [
    "Contact local NRLM office",
    "Join/form Self Help Group",
    "Complete basic training",
    "Develop livelihood plan",
    "Access financial support"
  ],
  eligibility: [
    "Rural poor households",
    "Women Self Help Groups",
    "Village organizations",
    "Cluster level federations"
  ],
  benefits: [
    "Financial assistance",
    "Skill development",
    "Market linkages",
    "Interest subvention",
    "Institutional support"
  ],
  requirements: [
    "Proof of rural residence",
    "Income certificate",
    "SHG membership details",
    "Bank account",
    "Basic training completion"
  ],
  faqs: [
    {
      question: "What support is provided to SHGs?",
      answer: "SHGs receive financial support, capacity building, and market linkage assistance."
    },
    {
      question: "Is training provided under NRLM?",
      answer: "Yes, comprehensive training is provided for skill development and enterprise management."
    },
    {
      question: "What is the maximum loan amount available?",
      answer: "The loan amount varies based on the business plan and group capacity, typically ranging from ₹50,000 to several lakhs."
    },
    {
      question: "How many members can be in an SHG?",
      answer: "An SHG typically consists of 10-20 members from similar socio-economic backgrounds."
    },
    {
      question: "Is there any subsidy available?",
      answer: "Yes, various subsidies are available based on the type of activity and category of beneficiaries."
    },
    {
      question: "Can men join NRLM programs?",
      answer: "While NRLM primarily focuses on women, there are specific components where men can participate."
    }
  ],
  moreAboutThisScheme: `
    NRLM has transformed rural livelihoods through its focus on community institutions, financial inclusion, and sustainable livelihood opportunities.
  `
};

export const PMFasalBimaData = {
  title: "Pradhan Mantri Fasal Bima Yojana",
  schemeLink: "/scheme-agriculture-and-rural-development/pmfbypm-fasal-bima",
  description: `
    **Pradhan Mantri Fasal Bima Yojana (PMFBY)** is a comprehensive crop insurance scheme that provides coverage against crop loss due to natural calamities, pests, and diseases.
  `,
  applyLink: "https://pmfby.gov.in",
  stepsToApply: [
    "Visit local bank/insurance company",
    "Fill crop insurance form",
    "Submit required documents",
    "Pay premium amount",
    "Obtain insurance certificate"
  ],
  eligibility: [
    "All farmers growing notified crops",
    "Loanee and non-loanee farmers",
    "Sharecroppers and tenant farmers",
    "Farmers in notified areas"
  ],
  benefits: [
    "Comprehensive risk coverage",
    "Low premium rates",
    "Quick claim settlement",
    "Coverage for standing crops",
    "Use of technology for assessment"
  ],
  requirements: [
    "Land records",
    "Crop sowing certificate",
    "Bank account details",
    "Identity proof",
    "Premium payment"
  ],
  faqs:  [
    {
      question: "What crops are covered under PMFBY?",
      answer: "Food crops, oilseeds, and commercial/horticultural crops notified by state governments."
    },
    {
      question: "How are claims settled?",
      answer: "Claims are settled based on yield assessment and weather parameters using modern technology."
    },
    {
      question: "What is the premium rate?",
      answer: "Farmers pay 2% for Kharif crops, 1.5% for Rabi crops, and 5% for commercial/horticultural crops."
    },
    {
      question: "When should I apply for insurance?",
      answer: "Application should be made before the cut-off date, which is typically before the sowing season."
    },
    {
      question: "Is PMFBY mandatory for loanee farmers?",
      answer: "Yes, it is mandatory for farmers who take crop loans from banks."
    },
    {
      question: "How long does claim settlement take?",
      answer: "Claims are typically settled within 2 months after crop cutting experiments and data processing."
    }
  ],
  moreAboutThisScheme: `
    PMFBY has revolutionized crop insurance by making it more farmer-friendly with reduced premiums and better coverage, helping farmers manage agricultural risks effectively.
  `
};