import React from 'react';
import { useLocation } from 'react-router-dom';
import SchemeDetail from './SchemeDetail';
import {
  PMAYUrbanData,
  SmartCitiesMissionData,
  AMRUTData,
  RERAActData,
  DAYNULMData
} from './dataDetails';

const allSchemes = [
  PMAYUrbanData,
  SmartCitiesMissionData,
  AMRUTData,
  RERAActData,
  DAYNULMData
];

const SchemeDetailHousing = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const currentScheme = allSchemes.find(scheme => currentUrl === scheme.schemeLink);

  if (!currentScheme) {
    return <div>Scheme not found!</div>;
  }

  return <SchemeDetail data={currentScheme} />;
};

export default SchemeDetailHousing;