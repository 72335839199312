import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../../config';

const EasyNavigationButton = () => {
  const [navigationLinks, setNavigationLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.BASE_URL}/api/structure/easy-navigation/all`, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const navigationLinks = await response.json();
        setNavigationLinks(navigationLinks.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getButtonStyles = (index) => {
    const styles = [
      'bg-gradient-to-br from-purple-700 to-purple-300 hover:from-purple-500 hover:to-slate-400',
      'bg-gradient-to-br from-orange-700 to-orange-300 hover:from-orange-500 hover:to-slate-400',
      'bg-gradient-to-br from-sky-700 to-sky-300 hover:from-sky-500 hover:to-slate-400',
      'bg-gradient-to-br from-red-700 to-red-300 hover:from-red-500 hover:to-slate-400',
      'bg-gradient-to-br from-yellow-700 to-yellow-300 hover:from-yellow-500 hover:to-slate-400',
      'bg-gradient-to-br from-blue-700 to-blue-300 hover:from-blue-500 hover:to-slate-400',
      'bg-gradient-to-br from-emerald-700 to-emerald-300 hover:from-emerald-500 hover:to-slate-400',
      'bg-gradient-to-br from-pink-700 to-pink-300 hover:from-pink-500 hover:to-slate-400',
    ];
    return styles[index % styles.length]; 
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='mt-4 mb-4 pb-2 bg-green-50 shadow-md'>
      <h2 className='text-center text-2xl p-4 font-bold underline'>Top on SarkariLock</h2>
      <ul className='flex flex-wrap gap-x-5 sm:gap-x-7 gap-2 m-2 p-2 justify-center text-lg list-none'>
        {navigationLinks.map((linkItem, index) => (
          <li key={linkItem._id}>
            <Link
              to={linkItem.link}
              className={`block px-3 rounded-lg py-1 md:text-lg text-sm text-slate-200 text-center easy-navigation-button-small-screen shadow-lg ${getButtonStyles(index)}`}
            >
              {linkItem.linkLabelEasyNav}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EasyNavigationButton;
