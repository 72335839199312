export const AyushmanBharatData = {
    title: "Ayushman Bharat",
    schemeLink:"/scheme-health/ayushman-bharat",
    description: `
      **Ayushman Bharat** is a flagship health initiative introduced by the Government of India in 2018. 
      Its primary aim is to provide Universal Health Coverage (UHC) and improve healthcare access, especially for economically disadvantaged sections of society.
  
      **History**:
      Conceptualized under the National Health Policy, Ayushman Bharat consolidates multiple existing healthcare schemes into one unified framework. The initiative is focused on offering financial protection to vulnerable families through health insurance.
    `,
    applyLink: "https://www.pmjay.gov.in",
    stepsToApply: [
      "Visit the official Ayushman Bharat website or contact your nearest Common Service Center (CSC).",
      "Check your eligibility by entering your details such as Aadhaar number or mobile number using the eligibility checker.",
      "Create an account or log in with your personal details like name, age, and address.",
      "Fill out the application form and upload necessary documents such as ID proof and eligibility proof.",
      "Submit the application and save the acknowledgment receipt for future reference.",
      "Track the status of your application through the website’s tracking feature."
    ],
    eligibility: [
      "Families identified under the Socio-Economic Caste Census (SECC) database.",
      "Individuals in urban worker categories such as ragpickers, beggars, and domestic workers.",
      "Households with no adults aged 16-59 years.",
      "Manual scavenger families and those with a single-room living condition.",
      "People who hold valid Ayushman Bharat cards issued by the government."
    ],
    benefits: [
      "Up to ₹5 lakh per family per year for secondary and tertiary care hospitalization.",
      "Coverage for over 1,500 medical procedures, including surgeries, diagnostics, and medicines.",
      "Pre- and post-hospitalization expenses are covered for up to 15 days.",
      "No age, gender, or family size restrictions.",
      "Transportation costs up to ₹2,000 per hospitalization are covered.",
      "Cashless treatment at more than 24,000 empaneled hospitals across India."
    ],
    requirements: [
      "Valid ID proof (Aadhaar card, PAN card, or voter ID).",
      "Eligibility proof from the SECC database.",
      "Ration card or income certificate for verification purposes.",
      "Bank account details, if necessary, for financial transactions."
    ],
    coveredDiseases: [
      "Heart surgeries, including bypass and valve replacements.",
      "Cancer treatments such as chemotherapy and radiation therapy.",
      "Kidney transplants and dialysis services.",
      "Neurological disorders and brain surgeries.",
      "Orthopedic procedures, including joint replacements.",
      "Maternity care and neonatal care for infants."
    ],
    exclusions: [
      "Outpatient (OPD) consultations and treatments.",
      "Cosmetic surgeries and aesthetic procedures.",
      "Drug rehabilitation treatments.",
      "Fertility treatments and surrogacy.",
      "Treatments that are not prescribed by empaneled doctors.",
      "Conditions resulting from self-harm or substance abuse."
    ],
    applicationStatusTracking: {
      description:
        "Applicants can monitor the status of their application online using the acknowledgment receipt number or their registered mobile number.",
      trackingLink: "https://www.pmjay.gov.in/application-status"
    },
    officialWebsite: "https://www.pmjay.gov.in",
    faqs: [
      {
        question: "How can I check if I am eligible for Ayushman Bharat?",
        answer: "You can check your eligibility by entering your Aadhaar number, mobile number, or SECC details on the official website."
      },
      {
        question: "Is there any age limit for beneficiaries?",
        answer: "No, the Ayushman Bharat scheme does not impose any age or gender restrictions for beneficiaries."
      },
      {
        question: "Can I apply offline for Ayushman Bharat?",
        answer: "Yes, you can visit your nearest Common Service Center (CSC) to apply offline."
      },
      {
        question: "What is the maximum health coverage under Ayushman Bharat?",
        answer: "The scheme provides coverage of up to ₹5 lakh per family per year for secondary and tertiary care hospitalization."
      },
      {
        question: "Are there any restrictions on the type of hospital for treatment?",
        answer: "No, Ayushman Bharat provides cashless treatment at over 24,000 empaneled hospitals across India."
      },
      {
        question: "Is pre-existing illness covered under the scheme?",
        answer: "Yes, pre-existing illnesses are covered from the day of enrollment."
      },
      {
        question: "Can I apply for my family members?",
        answer: "Yes, you can apply for your eligible family members under the scheme."
      },
      {
        question: "How can I check the status of my application?",
        answer: "You can check your application status by visiting the official website and entering your acknowledgment receipt number or registered mobile number."
      },
      {
        question: "What documents are required to apply for Ayushman Bharat?",
        answer: "You will need documents such as Aadhaar card, ration card, and eligibility proof from the SECC database."
      },
      {
        question: "Is there any cost for enrolling in Ayushman Bharat?",
        answer: "No, enrolling in the Ayushman Bharat scheme is completely free of charge."
      }
    ],
    moreAboutThisScheme: `
      Ayushman Bharat is the world’s largest health insurance scheme, covering over 10 crore families across India. Designed to provide financial protection against high medical expenses, the scheme aims to reduce the burden of healthcare costs that can lead to poverty. It focuses on bridging the gap between rural and urban healthcare access, ensuring that quality medical treatment is available to all citizens, regardless of their geographic location or economic background.

For more information about the Ayushman Bharat scheme, please visit the official government website. Always verify the authenticity of the information through trusted and official government resources to ensure its accuracy and relevance.
    `
  };



  export const PMJanArogyaData = {
    title: "PM Jan Arogya Yojana",
    schemeLink:"/scheme-health/jan-arogya",
    description: `
      **PM Jan Arogya Yojana** is a government initiative aimed at providing free healthcare services for critical illnesses. It focuses on ensuring that low-income families across India have access to quality medical treatment without facing financial hardships. The scheme helps reduce the burden of high medical expenses, offering essential healthcare benefits to the most vulnerable sections of society.
    
      **Key Highlights**:
      - Free medical treatment for serious health conditions.
      - Coverage for hospitalization, surgeries, diagnostics, and medicines.
      - Aimed at economically disadvantaged families in both rural and urban areas.
    `,
    applyLink: "https://www.pmjay.gov.in",
    stepsToApply: [
      "Visit the official PM Jan Arogya Yojana website or visit a Common Service Center (CSC).",
      "Check your eligibility using the provided eligibility checker by entering details like Aadhaar number or mobile number.",
      "Create an account or log in using your personal details (name, age, address).",
      "Fill in the application form and upload necessary documents like identity proof and eligibility documents.",
      "Submit your application and save the acknowledgment receipt for reference.",
      "Track your application status through the official website."
    ],
    eligibility: [
      "Families identified under the Socio-Economic Caste Census (SECC) database.",
      "Individuals belonging to specific vulnerable groups such as ragpickers, beggars, and domestic workers.",
      "Households with no adults aged 16-59 years.",
      "Manual scavenger families or families living in a single-room condition.",
      "People with valid Jan Arogya Yojana cards issued by the government."
    ],
    benefits: [
      "Free medical treatment for serious illnesses such as cancer, heart disease, kidney problems, and more.",
      "No age or family size restrictions.",
      "Coverage for hospitalization, surgeries, diagnostic tests, and essential medicines.",
      "Transportation expenses up to ₹2,000 per hospitalization covered.",
      "Cashless treatment at hospitals across the country."
    ],
    requirements: [
      "Valid ID proof (Aadhaar card, PAN card, voter ID).",
      "Eligibility proof as per the SECC database.",
      "Ration card or income certificate for verification purposes.",
      "Bank details for financial transactions, if necessary."
    ],
    coveredDiseases: [
      "Heart surgeries, including bypass and valve replacements.",
      "Cancer treatments such as chemotherapy and radiation.",
      "Kidney transplants and dialysis services.",
      "Neurological conditions and brain surgeries.",
      "Orthopedic surgeries, including joint replacements.",
      "Maternity care and neonatal services."
    ],
    exclusions: [
      "Outpatient (OPD) consultations and treatments.",
      "Cosmetic or aesthetic procedures.",
      "Drug rehabilitation treatments.",
      "Fertility treatments or surrogacy.",
      "Conditions resulting from self-harm or substance abuse.",
      "Treatments not recommended by empaneled doctors."
    ],
    applicationStatusTracking: {
      description:
        "Track the status of your application online using your acknowledgment receipt number or mobile number.",
      trackingLink: "https://www.pmjay.gov.in/application-status"
    },
    officialWebsite: "https://www.pmjay.gov.in",
    faqs: [
      {
        question: "How can I check my eligibility for PM Jan Arogya Yojana?",
        answer: "You can check eligibility by entering your Aadhaar number or mobile number on the official website."
      },
      {
        question: "Is there any age restriction for the scheme?",
        answer: "No, there are no age or family size restrictions under PM Jan Arogya Yojana."
      },
      {
        question: "Can I apply offline for PM Jan Arogya Yojana?",
        answer: "Yes, you can apply offline by visiting your nearest Common Service Center (CSC)."
      },
      {
        question: "What benefits does the scheme offer?",
        answer: "The scheme offers free medical treatment for critical illnesses, including surgeries, hospitalizations, and diagnostic tests."
      },
      {
        question: "Is pre-existing illness covered under this scheme?",
        answer: "Yes, pre-existing conditions are covered under the PM Jan Arogya Yojana."
      },
      {
        question: "How can I track my application status?",
        answer: "You can track your application status by visiting the official website and entering your acknowledgment receipt number or mobile number."
      },
      {
        question: "What documents are required to apply for PM Jan Arogya Yojana?",
        answer: "Required documents include Aadhaar card, ration card, income certificate, and eligibility proof from the SECC database."
      },
      {
        question: "Are there any charges for applying?",
        answer: "No, applying for PM Jan Arogya Yojana is completely free."
      }
    ],
    moreAboutThisScheme: `
      PM Jan Arogya Yojana is a critical healthcare initiative by the Government of India, offering free medical treatment to families in need. The scheme aims to reduce the financial burden caused by serious illnesses and provide access to quality healthcare services across the nation. This initiative is especially beneficial for those in rural areas and low-income urban populations who previously struggled with high medical expenses.
  
      For more details about PM Jan Arogya Yojana, visit the official website and ensure you are using verified information from trusted government resources.
    `
  };

  export const NationalHealthMissionData = {
    schemeLink:"/scheme-health/national-health-mission",
    title: "National Health Mission (NHM)",
    description: `
      The **National Health Mission (NHM)** is a comprehensive healthcare initiative by the Government of India that aims to provide accessible, affordable, and quality healthcare to all citizens, with a special focus on vulnerable and underserved populations. Launched in 2005, NHM seeks to improve the health outcomes of the nation by strengthening the healthcare infrastructure, especially in rural and remote areas.
    
      **Key Objectives**:
      - Improve maternal and child health.
      - Address communicable and non-communicable diseases.
      - Provide quality healthcare services at the grassroots level.
      - Strengthen the healthcare system to ensure sustainable health development.
    `,
    applyLink: "https://nhm.gov.in",
    stepsToApply: [
      "Visit the official National Health Mission website or local health centers for more details.",
      "Check for any available state-specific health schemes under NHM.",
      "If applicable, complete the application form and submit it to the respective district or state health department.",
      "Submit any required documents such as identity proof and address proof.",
      "Track the status of your application by visiting the official website or local health centers."
    ],
    eligibility: [
      "Individuals and families residing in rural, remote, and underserved areas.",
      "Women and children, especially those with limited access to quality healthcare.",
      "Pregnant women seeking maternal health services under the program.",
      "Communities affected by communicable diseases such as tuberculosis, malaria, and leprosy.",
      "People seeking public health services for non-communicable diseases like diabetes and hypertension."
    ],
    benefits: [
      "Improvement in healthcare infrastructure, especially in rural and remote regions.",
      "Access to maternal and child health services, including antenatal care, immunization, and safe delivery.",
      "Free or subsidized healthcare services for diseases like tuberculosis, malaria, and leprosy.",
      "Support for non-communicable disease management like diabetes, hypertension, and cancer.",
      "Promotion of health awareness and education at the community level."
    ],
    requirements: [
      "Valid ID proof (Aadhaar card, voter ID, etc.).",
      "Residence proof for rural and underserved areas.",
      "Medical records if applicable (for maternal health, chronic disease management).",
      "Income certificates for some targeted schemes under NHM."
    ],
    coveredDiseases: [
      "Maternal health services including prenatal and postnatal care.",
      "Child health services, including vaccination and malnutrition management.",
      "Tuberculosis, malaria, and leprosy treatments.",
      "Chronic diseases like diabetes, hypertension, and cancer.",
      "Mental health disorders and addictions."
    ],
    exclusions: [
      "Cosmetic surgeries and non-essential treatments.",
      "Outpatient consultations not related to public health schemes.",
      "Services that are not part of the official NHM guidelines or recommendations."
    ],
    applicationStatusTracking: {
      description:
        "Applicants can track the status of their application by visiting the NHM website or contacting their local health centers.",
      trackingLink: "https://nhm.gov.in"
    },
    officialWebsite: "https://nhm.gov.in",
    faqs: [
      {
        question: "What services are covered under the National Health Mission?",
        answer: "NHM covers maternal and child health, infectious disease management, non-communicable disease care, and health infrastructure improvements."
      },
      {
        question: "How can I apply for NHM services?",
        answer: "You can apply for NHM services by visiting the official website or your local health center to check eligibility and application procedures."
      },
      {
        question: "Is NHM available in all states?",
        answer: "Yes, NHM is implemented nationwide, with specific schemes and services varying by state and region."
      },
      {
        question: "What documents are required to apply for NHM?",
        answer: "Documents may include Aadhaar card, voter ID, income certificate, and medical records, depending on the scheme."
      },
      {
        question: "Can I apply for NHM health services online?",
        answer: "Yes, you can check for services and application details on the NHM website and apply as per the state-specific requirements."
      },
      {
        question: "Is there any cost for services under NHM?",
        answer: "Services provided under NHM, especially for maternal and child health and communicable diseases, are free or highly subsidized."
      }
    ],
    moreAboutThisScheme: `
      The **National Health Mission (NHM)** is an ambitious health initiative by the Government of India that aims to provide accessible and affordable healthcare to all citizens, particularly focusing on rural and underserved communities. The program not only addresses maternal and child health but also aims to combat the burden of both communicable and non-communicable diseases in India. By strengthening the healthcare infrastructure and focusing on health education and awareness at the grassroots level, NHM plays a pivotal role in the health sector's overall improvement.
  
      For more information on services, eligibility, and application details, please visit the official NHM website. Always ensure to verify the authenticity of the information through trusted and official government resources to guarantee accuracy and relevance.
    `
  };


  export const RashtriyaSwasthyaBimaYojanaData = {
    schemeLink:"/scheme-health/rashtriya-swasthya-bima",
    title: "Rashtriya Swasthya Bima Yojana (RSBY)",
    description: `
      **Rashtriya Swasthya Bima Yojana (RSBY)** is a health insurance scheme introduced by the Government of India aimed at providing financial protection to families living below the poverty line (BPL). Launched in 2008, the scheme aims to reduce the financial burden on poor families caused by hospitalization expenses, ensuring they have access to quality healthcare services without facing financial hardships.
    
      **Key Objectives**:
      - To provide cashless hospitalization services to families living below the poverty line.
      - To offer financial protection against high medical costs, especially for serious illnesses and emergencies.
      - To improve the access to healthcare services in both rural and urban areas for vulnerable populations.
    `,
    applyLink: "https://www.rsby.gov.in",
    stepsToApply: [
      "Visit the official Rashtriya Swasthya Bima Yojana website or your nearest health center for more details.",
      "Check your eligibility based on the family’s BPL status.",
      "Register your family by submitting the necessary documents such as Aadhaar card, BPL certificate, and other identity proof.",
      "Obtain your RSBY smart card, which will be used for cashless treatment at empaneled hospitals.",
      "Once registered, your family will be covered under the scheme for hospitalization services.",
      "Track the status of your application and benefits using the official website or by contacting local health centers."
    ],
    eligibility: [
      "Families classified as below the poverty line (BPL) in both urban and rural areas.",
      "Families who do not have access to other forms of health insurance.",
      "Individuals identified under the SECC (Socio-Economic Caste Census) database as eligible for the scheme."
    ],
    benefits: [
      "Up to ₹30,000 per family per year for hospitalization expenses.",
      "Cashless treatment at over 15,000 empaneled hospitals across India.",
      "Coverage for pre-existing diseases and maternity benefits.",
      "Free medical treatments for major surgeries, including cardiac care, cancer treatments, and orthopedic surgeries.",
      "Transportation allowance for patients traveling to the hospital, up to ₹100 per hospitalization."
    ],
    requirements: [
      "BPL certificate or SECC identification as proof of eligibility.",
      "Aadhaar card or any government-issued identity proof.",
      "Photographs of the family members for the RSBY smart card.",
      "Income certificate or relevant documentation (if applicable)."
    ],
    coveredDiseases: [
      "Cardiac surgeries, including bypass and valve replacements.",
      "Cancer treatments such as chemotherapy and radiation therapy.",
      "Kidney transplants and dialysis services.",
      "Orthopedic procedures like joint replacements and fracture treatment.",
      "Maternity care, including normal delivery, cesarean, and related complications."
    ],
    exclusions: [
      "Outpatient treatments and consultations.",
      "Cosmetic surgeries or treatments for aesthetic purposes.",
      "Self-harm treatments or injuries caused by substance abuse.",
      "Alternative treatments like homeopathy or naturopathy.",
      "Treatments that are not covered under the approved RSBY list of diseases."
    ],
    applicationStatusTracking: {
      description:
        "Applicants can track the status of their application online using their RSBY smart card number or by contacting the nearest district health office.",
      trackingLink: "https://www.rsby.gov.in"
    },
    officialWebsite: "https://www.rsby.gov.in",
    faqs: [
      {
        question: "How can I apply for Rashtriya Swasthya Bima Yojana?",
        answer: "You can apply by visiting the official RSBY website or your local health center to submit the necessary documents and register."
      },
      {
        question: "What is the maximum coverage under RSBY?",
        answer: "RSBY provides a coverage of up to ₹30,000 per family per year for hospitalization expenses."
      },
      {
        question: "Is there any age limit for enrolling in RSBY?",
        answer: "No, there is no age limit for enrolling in RSBY. It covers the entire family, including children, adults, and elderly members."
      },
      {
        question: "Are pre-existing conditions covered under RSBY?",
        answer: "Yes, pre-existing conditions are covered from day one under Rashtriya Swasthya Bima Yojana."
      },
      {
        question: "What hospitals can I visit for cashless treatment under RSBY?",
        answer: "RSBY provides cashless treatment at over 15,000 empaneled hospitals across India."
      },
      {
        question: "How can I check my RSBY application status?",
        answer: "You can check your application status online on the RSBY website or by visiting the nearest district health office."
      }
    ],
    moreAboutThisScheme: `
      Rashtriya Swasthya Bima Yojana (RSBY) is a crucial initiative by the Government of India aimed at providing health insurance to the poorest families in the country. By offering cashless hospitalization benefits, the scheme reduces the financial burden on families facing medical emergencies or serious illnesses. The scheme also covers a wide range of treatments, including maternity care, surgery, and post-hospitalization expenses. With the issuance of RSBY smart cards, beneficiaries can avail of healthcare services without worrying about upfront medical costs.
      
      For more information, please visit the official RSBY website. Ensure you verify the details through trusted government sources for the most accurate and up-to-date information.
    `
  };


  export const JananiSurakshaYojanaData = {
    schemeLink:"/scheme-health/janani-suraksha",
    title: "Janani Suraksha Yojana (JSY)",
    description: `
      **Janani Suraksha Yojana (JSY)** is a flagship maternal health scheme launched by the Government of India to reduce maternal and neonatal mortality by ensuring institutional delivery and providing financial assistance to pregnant women. The scheme primarily aims at promoting safe motherhood by encouraging women to give birth in a health facility, ensuring both maternal and child health are adequately cared for.
  
      **Key Objectives**:
      - To reduce maternal mortality rates by promoting safe delivery practices.
      - To encourage institutional deliveries for better maternal and child health outcomes.
      - To provide financial support to economically disadvantaged pregnant women to facilitate institutional delivery.
    `,
    applyLink: "https://www.nhp.gov.in/janani-suraksha-yojana-jsy",
    stepsToApply: [
      "Visit the official JSY website or your local health center for detailed information on the scheme.",
      "Check your eligibility based on the socio-economic status of your family (BPL status).",
      "Register for the scheme at your nearest health facility or with the Anganwadi worker.",
      "Submit the required documents, such as identity proof, BPL certificate, and other necessary paperwork.",
      "Receive the financial assistance through cash or cheque for delivery in a recognized government or private health facility.",
      "Ensure your institutional delivery is supported and documented by the concerned health staff."
    ],
    eligibility: [
      "Pregnant women from Below Poverty Line (BPL) families.",
      "Women aged 19 years and above for the first two live births.",
      "Women who undergo institutional deliveries in government or accredited private health facilities.",
      "Women with pregnancies less than 5 live children."
    ],
    benefits: [
      "Financial assistance for institutional delivery, ranging from ₹700 to ₹1,400 depending on the location (rural or urban).",
      "Coverage of transportation costs for women who need to travel to the hospital for delivery.",
      "Support for maternal and newborn health check-ups post-delivery.",
      "Promotes safe delivery practices in health facilities to reduce the risks of childbirth."
    ],
    requirements: [
      "BPL certificate to verify eligibility.",
      "Aadhaar card or any government-issued identity proof.",
      "Details of previous pregnancies (if applicable).",
      "Certificate from a health facility confirming institutional delivery."
    ],
    coveredDiseases: [
      "Pre-natal care and post-natal care support.",
      "Emergency services for labor and delivery.",
      "Antenatal check-ups and diagnostic tests.",
      "Postnatal check-ups for mother and child, including immunization and breastfeeding support."
    ],
    exclusions: [
      "Outpatient consultations and treatments.",
      "Non-institutional deliveries (home births).",
      "Elective or non-medical interventions during pregnancy.",
      "Cosmetic or elective procedures during childbirth."
    ],
    applicationStatusTracking: {
      description:
        "Applicants can track their application status and check for updates regarding the financial assistance through the official health department portal or by contacting local health centers.",
      trackingLink: "https://www.nhp.gov.in/janani-suraksha-yojana-jsy"
    },
    officialWebsite: "https://www.nhp.gov.in/janani-suraksha-yojana-jsy",
    faqs: [
      {
        question: "How can I apply for Janani Suraksha Yojana?",
        answer: "You can apply by visiting your local health center or through the Anganwadi worker. They will assist you with the registration process."
      },
      {
        question: "What is the financial assistance provided under JSY?",
        answer: "The financial assistance provided is up to ₹1,400 for institutional deliveries in rural and urban areas, depending on the location."
      },
      {
        question: "What are the eligibility criteria for JSY?",
        answer: "The scheme is available for pregnant women from BPL families who are undergoing institutional delivery and for women aged 19 years and above."
      },
      {
        question: "Can I receive JSY benefits for my second pregnancy?",
        answer: "Yes, if it is your first or second pregnancy, you are eligible for the benefits under JSY."
      },
      {
        question: "Is JSY applicable in both government and private hospitals?",
        answer: "Yes, the scheme covers institutional deliveries in both government and accredited private hospitals."
      },
      {
        question: "How can I track my JSY application status?",
        answer: "You can track your application status online through the official website or by contacting your nearest health center."
      }
    ],
    moreAboutThisScheme: `
      Janani Suraksha Yojana (JSY) is part of India’s efforts to promote maternal health and reduce maternal and neonatal mortality. By providing financial assistance to economically disadvantaged pregnant women, the scheme ensures that they have access to safe and institutionalized deliveries. Institutional deliveries have been shown to reduce the risks associated with childbirth, including complications that could affect both mother and baby. JSY encourages more women to choose hospitals for delivery, where trained professionals and better medical facilities are available.
  
      For further information, please visit the official website of JSY. Ensure to verify the authenticity of the information through trusted and official sources to stay updated about the scheme’s benefits and application process.`
  };
  
  
  
   