import React, { useEffect, useState } from 'react';
import SliderLink from './SliderLink';
import config from '../../../../../config';

const TextSlider = () => {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSliderLinks = async () => {
      try {
        const response = await fetch(`${config.BASE_URL}/api/structure/slider-link/all`);
        if (!response.ok) {
          throw new Error('Failed to fetch slider links');
        }
        const result = await response.json();
        setSliderData(result.data);
      } catch (error) {
        console.error('Error fetching slider links:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSliderLinks();
  }, []);

  // Group the links into lines (assuming identifiers contain "First", "Second", "Third")
  const groupedLinks = {
    firstLine: sliderData.filter(item => item.adminLinkIdentifier.includes('First')),
    secondLine: sliderData.filter(item => item.adminLinkIdentifier.includes('Second')),
    thirdLine: sliderData.filter(item => item.adminLinkIdentifier.includes('Third')),
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative overflow-hidden">
      {/* Dynamically Render SliderLink Components */}
      {Object.entries(groupedLinks).map(([key, links], index) => (
        <SliderLink
          key={key}
          link1={links[0]?.link || '#'}
          linkLabel1={links[0]?.linkLabelSlider || ''}
          link2={links[1]?.link || '#'}
          linkLabel2={links[1]?.linkLabelSlider || ''}
          link3={links[2]?.link || '#'}
          linkLabel3={links[2]?.linkLabelSlider || ''}
          textSlide={`textSlider${index === 0 ? '' : index === 1 ? 'fast' : 'faster'}`}
        />
      ))}
    </div>
  );
};

export default TextSlider;
