import React from 'react';
import { FaArrowRight } from 'react-icons/fa'; // Importing icon for visual cue
import { Link } from 'react-router-dom';
const SarkariYojanas = () => {
  return (
    <Link to="/sarkari-yojana">
    <div className="bg-gradient-to-r from-blue-700 via-teal-600 to-green-500 text-white py-6 px-8 rounded-lg shadow-lg max-w-3xl mx-auto my-10 cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-2xl">
      <div className="text-center">
        <h1 className="text-3xl font-extrabold tracking-tight mb-4">Explore Sarkari Yojanas</h1>
        <p className="text-lg italic opacity-90 mb-6">
          Discover a wide range of government welfare schemes across sectors like agriculture, healthcare, education, and employment.
        </p>
        <div className="flex items-center justify-center space-x-2">
          <span className="font-semibold text-lg">Explore Schemes</span>
          <FaArrowRight className="text-xl" />
        </div>
      </div>
    </div>
    </Link>
  );
};

export default SarkariYojanas;
