export const MidDayMealData = {
  title: "Mid-Day Meal Scheme",
  schemeLink: "/scheme-education-scholarship/mid-day-meal",
  description: `
    The **Mid-Day Meal Scheme** is the world's largest school meal program aimed at enhancing enrollment, retention, and attendance while improving nutritional levels among children. The scheme provides hot cooked meals to school children across India.

    **Key Objectives**:
    - Improve nutritional status of school-going children
    - Encourage poor children to attend school regularly
    - Provide nutritional support to children of primary stage
  `,
  applyLink: "https://mdm.nic.in",
  stepsToApply: [
    "Children enrolled in government and government-aided schools are automatically covered",
    "No separate application required",
    "Parents can contact school authorities for any queries",
    "School management committees oversee implementation"
  ],
  eligibility: [
    "Children studying in Classes I-VIII in government schools",
    "Children in government-aided schools",
    "Students in Madrasa and Maqtabs",
    "Education Guarantee Scheme (EGS) centers"
  ],
  benefits: [
    "Free nutritious cooked meal every working day",
    "Prescribed nutritional standards for primary and upper primary students",
    "Safe and hygienic food preparation",
    "Regular health check-ups",
    "Micronutrient supplementation"
  ],
  requirements: [
    "Enrollment in eligible school",
    "Regular attendance",
    "School ID card",
    "Parent/Guardian details"
  ],
  additionalDetails: [
    "Primary (Classes I-V): 450 calories, 12g protein",
    "Upper Primary (Classes VI-VIII): 700 calories, 20g protein",
    "Fresh cooked meals as per menu",
    "Seasonal fruits and vegetables",
    "Food fortification for additional nutrients"
  ],
  faqs: [
    {
      question: "Is there any cost for the mid-day meal?",
      answer: "No, the mid-day meal is provided completely free of cost to all eligible students."
    },
    {
      question: "What is the menu for mid-day meals?",
      answer: "The menu varies by state but typically includes rice/rotis, pulses, vegetables, and sometimes eggs/fruits."
    },
    {
      question: "How is the quality of food ensured?",
      answer: "Regular quality checks are conducted by school management committees and food safety officers."
    },
    {
      question: "What happens during school holidays?",
      answer: "The scheme operates only during working school days."
    },
    {
      question: "Can parents inspect the meal preparation?",
      answer: "Yes, parents can be part of the school management committee and oversee meal preparation."
    }
  ],
  moreAboutThisScheme: `
    The Mid-Day Meal Scheme has significantly contributed to increased school enrollment and improved nutrition levels among children. It serves as a crucial social safety net and helps in addressing classroom hunger while promoting education.
  `
};


export const SarvaShikshaAbhiyanData = {
  title: "Sarva Shiksha Abhiyan",
  schemeLink: "/scheme-education-scholarship/sarva-shiksha-abhiyan",
  description: `
    **Sarva Shiksha Abhiyan (SSA)** is India's flagship programme for achievement of Universalization of Elementary Education in a time-bound manner. It aims to provide quality elementary education to all children in the age group of 6-14 years.
  `,
  applyLink: "https://seshagun.gov.in",
  stepsToApply: [
    "Visit nearest government school",
    "Contact local education authorities",
    "Submit required documents",
    "Complete enrollment process",
    "Attend regular classes"
  ],
  eligibility: [
    "Children aged 6-14 years",
    "Out-of-school children",
    "Children with special needs",
    "Girls and socially disadvantaged groups"
  ],
  benefits: [
    "Free elementary education",
    "Free textbooks and uniforms",
    "Special training for out-of-school children",
    "Infrastructure development in schools",
    "Quality education initiatives"
  ],
  requirements: [
    "Age proof",
    "Residence proof",
    "Previous education records (if any)",
    "Medical records (for children with special needs)"
  ],
  additionalDetails: [
    'Introduced in 1995 under the National Programme of Nutritional Support to Primary Education.',
    'Extended to upper primary students in 2008.',
    'Helps in achieving the Millennium Development Goal of universal primary education.'
  ],
  faqs:  [
    {
      question: "Is SSA only for government schools?",
      answer: "SSA primarily focuses on government schools but also supports government-aided schools."
    },
    {
      question: "What support is provided for children with special needs?",
      answer: "SSA provides inclusive education, special training, and assistive devices for children with special needs."
    },
    {
      question: "How does SSA ensure quality education?",
      answer: "Through teacher training, monitoring systems, and regular assessments."
    },
    {
      question: "Are there any age restrictions?",
      answer: "SSA focuses on children between 6-14 years of age."
    },
    {
      question: "What infrastructure support is provided?",
      answer: "Classrooms, toilets, drinking water, and maintenance grants are provided."
    }
  ],
  moreAboutThisScheme: `
    Sarva Shiksha Abhiyan has been instrumental in achieving near-universal enrollment in elementary education. The program focuses on bridging social, regional, and gender gaps in education while ensuring quality learning.
  `
};

export const NationalMeansScholarshipData = {
  title: "National Means-cum-Merit Scholarship",
  schemeLink: "/scheme-education-scholarship/nmms-national-means-merit-scholarship",
  description: `
    The **National Means-cum-Merit Scholarship (NMMSS)** scheme aims to award scholarships to meritorious students from economically weaker sections to reduce their drop-out rate in class VIII and encourage them to continue their education at secondary stage.
  `,
  applyLink: "https://scholarships.gov.in",
  stepsToApply: [
    "Register on National Scholarship Portal",
    "Fill in the application form",
    "Upload required documents",
    "Submit application before deadline",
    "Appear for scholarship examination"
  ],
  eligibility: [
    "Students studying in Class VIII",
    "Parental income below ₹1,50,000 per annum",
    "Minimum 55% marks in Class VII",
    "Regular students of government/aided schools"
  ],
  benefits: [
    "Scholarship of ₹12,000 per annum",
    "Financial support from Class IX to XII",
    "Direct transfer to bank account",
    "Support for education expenses"
  ],
  requirements: [
    "Income certificate",
    "Previous class marksheet",
    "Bank account details",
    "Aadhaar card",
    "School enrollment certificate"
  ],
  additionalDetails: [
    
      "Established in 2008-09 to support talented students",
      "Annual selection through state-level examination",
      "100,000 scholarships awarded annually nationwide",
      "Continuous academic performance monitoring",
      "Special provisions for students with disabilities"
  
  ],
  faqs: [
    {
      question: "How is the scholarship amount paid?",
      answer: "The scholarship amount is paid directly to the student's bank account through Direct Benefit Transfer (DBT)."
    },
    {
      question: "Is the scholarship renewable?",
      answer: "Yes, the scholarship is renewable annually subject to good academic performance."
    },
    {
      question: "What is the selection process?",
      answer: "Selection is based on a written examination and family income criteria."
    },
    {
      question: "Can private school students apply?",
      answer: "No, only government and aided school students are eligible."
    },
    {
      question: "What happens if I fail in any subject?",
      answer: "The scholarship may be discontinued if academic performance is poor."
    }
  ],
  moreAboutThisScheme: `
    The NMMSS scheme has been effective in supporting talented students from economically weaker sections to continue their education. It plays a crucial role in reducing dropout rates and promoting quality education.
  `
};

export const PragatiScholarshipData = {
  title: "Pragati Scholarship for Girls",
  schemeLink: "/scheme-education-scholarship/pragati-scholarship",
  description: `
    **Pragati Scholarship** is a MHRD scheme implemented by AICTE aimed at providing assistance for advancement of girls pursuing technical education. It seeks to promote technical education among girls and empower them through technical knowledge.
  `,
  applyLink: "https://www.aicte-pragati-saksham-gov.in",
  stepsToApply: [
    "Register on AICTE portal",
    "Fill online application form",
    "Upload required documents",
    "Submit application",
    "Track application status"
  ],
  eligibility: [
    "Girl students admitted to AICTE approved institutions",
    "Family income less than ₹8 lakh per annum",
    "Only one girl child per family",
    "Admission through centralized admission process"
  ],
  benefits: [
    "Tuition fee reimbursement up to ₹30,000 or actual, whichever is less",
    "₹2,000 per month for 10 months as contingency allowance",
    "Both day scholars and hostellers are eligible",
    "Merit-based selection"
  ],
  requirements: [
    "Income certificate",
    "Previous semester marksheet",
    "Admission letter",
    "Bank account details",
    "Aadhaar card"
  ],
  additionalDetails: 
    [
      "Launched in 2014-15 academic year",
      "4000 scholarships awarded annually",
      "Covers all AICTE approved courses",
      "Supernumerary seats for scholarship holders",
      "Mentorship support from AICTE"
    ],
  
  faqs:[
    {
      question: "Can second girl child apply for Pragati scholarship?",
      answer: "No, the scholarship is only for families with one girl child."
    },
    {
      question: "Is this scholarship available for private college students?",
      answer: "Yes, if the institution is AICTE approved."
    },
    {
      question: "What is the renewal process?",
      answer: "Annual renewal based on academic performance and continued eligibility."
    },
    {
      question: "Can I apply for multiple scholarships?",
      answer: "Yes, but you must declare other scholarships received."
    },
    {
      question: "What happens if I change my course?",
      answer: "You must notify AICTE and reapply for the scholarship."
    }
  ],
  moreAboutThisScheme: `
    The Pragati Scholarship scheme has been instrumental in promoting technical education among girls and helping them achieve their career goals in technical fields. It has contributed significantly to increasing female participation in technical education.
  `
};

export const VidyalakshmiPortalData = {
  title: "Vidyalakshmi Portal",
  schemeLink: "/scheme-education-scholarship/vidyalakshmi-portal",
  description: `
    **Vidyalakshmi Portal** is a first-of-its-kind portal providing single window access to information about various educational loan schemes of banks and government scholarships. It aims to ensure that no student misses out on higher education due to lack of funds.
  `,
  applyLink: "https://www.vidyalakshmi.co.in",
  stepsToApply: [
    "Register on Vidyalakshmi Portal",
    "Fill in educational and personal details",
    "Search and compare loan schemes",
    "Apply to multiple banks",
    "Track application status"
  ],
  eligibility: [
    "Indian students seeking education loans",
    "Admission to recognized institutions",
    "Meeting bank-specific eligibility criteria",
    "Valid admission letter/proof"
  ],
  benefits: [
    "Single window for education loans",
    "Compare different loan schemes",
    "Apply to multiple banks simultaneously",
    "Track application status online",
    "Access to government schemes"
  ],
  requirements: [
    "Admission letter",
    "Academic records",
    "Income proof",
    "Address proof",
    "Collateral documents (as per bank requirements)"
  ],
  additionalDetails: 
    [
      "Launched in 2015 as a collaborative effort",
      "Integrated with major public and private sector banks",
      "Features AI-based loan eligibility checker",
      "Provides financial literacy resources",
      "Offers multilingual support in 8 languages"
    ]
  ,
  faqs: [
    {
      question: "Can I apply to multiple banks through this portal?",
      answer: "Yes, you can apply to up to three banks simultaneously through the portal."
    },
    {
      question: "Is there any fee for using Vidyalakshmi Portal?",
      answer: "No, the portal is completely free to use."
    },
    {
      question: "How long does the loan approval process take?",
      answer: "Processing time varies by bank, typically 2-4 weeks."
    },
    {
      question: "What happens if my loan is rejected?",
      answer: "You can apply to other banks or seek guidance through the portal's support system."
    },
    {
      question: "Can I track multiple applications?",
      answer: "Yes, the portal provides tracking for all submitted applications."
    }
  ],
  moreAboutThisScheme: `
    Vidyalakshmi Portal has simplified the process of accessing education loans and scholarships. It serves as a comprehensive platform for students seeking financial assistance for higher education, making the process more transparent and accessible.
  `
};