import React from 'react';
import logo from '../../assets/logo.jpg';
import { IoSearch, IoDocumentText, IoNewspaper } from "react-icons/io5";
import { FaIdCard } from "react-icons/fa";
import { RiMenu3Fill } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { IoMdHome } from "react-icons/io";
import HamMenu from './HamMenu';
import { Link } from 'react-router-dom';
import { MdWork } from "react-icons/md";

const Header = ({ isActiveHamMenu, setIsActiveHamMenu }) => {
  const closeMenu = () => {
    setIsActiveHamMenu(false);
  };

  const handleMenuClick = () => {
    setIsActiveHamMenu(prev => !prev);
  };

  return (
    <>
      <header className='bg-gradient-to-tl from-teal-600 via-teal-700 to-teal-900 h-16 shadow-xl flex items-center justify-center gap-16 sm:justify-between relative z-10'>
        <Link to="/">
          <div className='flex items-center gap-1'>
            <img src={logo} alt="logo" className='w-8 rounded-full cursor-pointer ml-6' />
            <h1 className='text-xl font-semibold gradient-text-flag cursor-pointer'>SarkariLock.IN</h1>
          </div>
        </Link>
        <nav className='lg:block hidden'>
          <ul className='text-slate-100 flex text-sm gap-3 items-center h-16 list-none'>
            <li className='flex text-center gap-1 whitespace-nowrap'>
              <Link className='hover:bg-teal-700 h-16 px-4 flex gap-1 items-center text-white cursor-pointer transition-colors duration-300' to="/">
                <IoMdHome className='text-lg' /> HOME
              </Link>
            </li>
            <li className='flex text-center gap-1 whitespace-nowrap'>
              <Link className='hover:bg-teal-700 h-16 px-4 flex gap-1 items-center text-white cursor-pointer transition-colors duration-300' to="/search-exam">
                <MdWork className='text-lg' /> LATEST JOBS
              </Link>
            </li>
            <li className='flex text-center gap-1 whitespace-nowrap'>
              <Link className='hover:bg-teal-700 h-16 px-4 flex gap-1 items-center text-white cursor-pointer transition-colors duration-300' to="/search-exam">
                <IoDocumentText className='text-lg' /> RESULTS
              </Link>
            </li>
            <li className='flex text-center gap-1 whitespace-nowrap'>
              <Link className='hover:bg-teal-700 h-16 px-4 flex gap-1 items-center text-white cursor-pointer transition-colors duration-300' to="/sarkari-yojana">
                <IoNewspaper className='text-lg' /> SARKARI YOJANAS
              </Link>
            </li>
            <li className='xl:flex text-center gap-1 whitespace-nowrap hidden '>
              <Link className='hover:bg-teal-700 h-16 px-4 flex gap-1 items-center text-white cursor-pointer transition-colors duration-300' to="/search-exam">
                <FaIdCard className='text-lg' /> ADMIT CARDS
              </Link>
            </li>
          </ul>
        </nav>
        <div className='flex items-center gap-8'>
          <div className='flex items-center gap-3'>
          <Link to='search-exam'><p className=' text-2xl text-slate-100 xl:pr-12'>
              <IoSearch />
            </p></Link> 
            <p className='p-1 border m-3 mr-[5vw] text-lg bg-teal-700 text-white hover:bg-teal-700 cursor-pointer' onClick={handleMenuClick}>
              {isActiveHamMenu ? <RxCross1 /> : <RiMenu3Fill />}
            </p>
          </div>
        </div>
      </header>
      <aside className={`rounded sm:w-3/12 w-9/12 bg-gradient-to-tl from-teal-500 to-teal-900 fixed right-0 z-10 ease-in-out duration-300 ${isActiveHamMenu ? 'translate-x-0' : 'translate-x-full'}`}>
        {isActiveHamMenu && <HamMenu closeMenu={closeMenu} />}
      </aside>
    </>
  );
};

export default Header;
