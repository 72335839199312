import React from 'react';
import { Link } from 'react-router-dom';
import {
  HealthAndWellbeing,
  EmploymentAndSkillDevelopment,
  EducationAndScholarships,
  EntrepreneurshipAndStartup,
  AgricultureAndRuralDevelopment,
  WomenAndChild,
  HousingAndUrbanDevelopment,
  FinancialInclusionAndSupport,
  EnvironmentAndEnergy,
} from '../allCategoryDetailList';


const allCategories = [
  HealthAndWellbeing,
  EmploymentAndSkillDevelopment,
  EducationAndScholarships,
  EntrepreneurshipAndStartup,
  AgricultureAndRuralDevelopment,
  WomenAndChild,
  HousingAndUrbanDevelopment,
  FinancialInclusionAndSupport,
  EnvironmentAndEnergy,
];


const CategoryCardDetailAll = () => {
  return (
    <div className="category-card-detail p-6 flex flex-col items-center">
      {allCategories.map((categoryData, categoryIndex) => {
      
        return (
          <div key={categoryIndex} className="w-[85vw] mb-12">
            {/* Category Title and Description */}
            <div className="mb-8 text-center">
              <h1 className="text-2xl md:text-3xl font-bold text-purple-600 flex items-center justify-center gap-2 mt-4 mb-5">
                <p className="text-green-600 text-5xl" >{categoryData.icon}</p>
                {categoryData.title}
              </h1>
              <p className="text-gray-700 mt-2">{categoryData.description}</p>
            </div>

            {/* Schemes Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {categoryData.schemes.map((scheme, index) => (
                <div
                  key={index}
                  className="scheme-card border shadow-md rounded-lg p-4 bg-gradient-to-r from-green-50 to-yellow-50 hover:shadow-lg transition"
                >
                  <h3 className="text-lg font-semibold text-purple-600">{scheme.title}</h3>
                  <p className="text-gray-700 mt-2">{scheme.description}</p>
                  <Link
                    to={scheme.link}
                    className="text-white bg-purple-500 hover:bg-purple-600 px-4 py-2 mt-3 inline-block rounded-md text-sm"
                  >
                    Learn More
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryCardDetailAll;
