import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CommingSoon from '../footer/CommingSoon';
import Home from './home/Home';
import ContactUs from './contact/ContactUs';
import AboutUs from './about/AboutUs';
import Base from './all-link-structure/Base';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';



import SchemeMain from '../Scheme-main/SchemeMain';
import CategoryCardDetailAll from '../Scheme-main/category-card-detail/CategoryCardDetailAll';
import CategoryCardDetail from '../Scheme-main/category-card-detail/CategoryCardDetail';

// Admin
import SliderLinksAdmin from '../admin/structure/SliderLinksAdmin';
import EasyNavigationAdmin from '../admin/structure/EasyNavigationAdmin';
import AdminPanelBase from '../admin/AdminPanelBase';
import ExploreOverview from '../admin/explore-overview/ExploreOverview';
import ExploreOverviewDetails from '../admin/explore-overview/ExploreOverviewDetails';
import ExploreMain from '../admin/explore-main/ExploreMain';
import ExploreMainDetails from '../admin/explore-main/ExploreMainDetails';
import LoginPage from '../admin/admin-auth/LoginPage';
import {useAuth} from '../admin/AuthContext'

// import SeeMoreLinkAdmission from './home/home-components/explore/see-more-link/SeeMoreLinkAdmission';
import SeeMoreLinkAdmitCard from './home/home-components/explore/see-more-link/SeeMoreLinkAdmitCard';
import SeeMoreLinkAnswerKey from './home/home-components/explore/see-more-link/SeeMoreLinkAnswerKey';
import SeeMoreLinkLatestJob from './home/home-components/explore/see-more-link/SeeMoreLinkLatestJob';
import SeeMoreLinkResults from './home/home-components/explore/see-more-link/SeeMoreLinkResult';
import SeeMoreLinkSyllabus from './home/home-components/explore/see-more-link/SeeMoreLinkSyllabus';

import SchemeDetailHealthUse from '../Scheme-main/All-scheme/health-and-welfare/SchemeDetailHealthUse';
import SchemeDetailEmployment from '../Scheme-main/All-scheme/employment-and-skill-dev/SchemeDetailEmployment';
import SchemeDetailAgriculture from '../Scheme-main/All-scheme/agriculture-and-rural-dev/SchemeDetailAgriculture';
import SchemeDetailEducation from '../Scheme-main/All-scheme/education-and-scholarship/SchemeDetailEducation';
import SchemeDetailEntrepreneurship from '../Scheme-main/All-scheme/entrepreneurship-and-startup/SchemeDetailEntrepreneurship';
import SchemeDetailEnvironment from '../Scheme-main/All-scheme/environment-and-energy/SchemeDetailEnvironment';
import SchemeDetailFinancial from '../Scheme-main/All-scheme/financial-inclusion-and-support/SchemeDetailFinancial';
import SchemeDetailHousing from '../Scheme-main/All-scheme/housing-and-urban-dev/SchemeDetailHousing';
import SchemeDetailWomenAndChild from '../Scheme-main/All-scheme/women-and-child/SchemeDetailWomenAndChild';




const BasicPagesRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Apply Helmet only on the Home route */}
      <Route
        path="/"
        element={
          <HelmetProvider>
            <>
              <Helmet>
                <title>SarkariLock</title>
                <meta
                  name="description"
                  content="Explore our Government Exam Information Portal for up-to-date details on exam dates, syllabi, eligibility criteria, and application processes. Stay informed on the latest job opportunities and government schemes."
                />
                <meta
                  name="keywords"
                  content="Government Exams, Exam Dates, Syllabus, Eligibility, Government Jobs, Exam Updates, Job Opportunities, Government Schemes"
                />
                <meta name="author" content="sarkarilock" />
              </Helmet>
              <Home />
            </>
          </HelmetProvider>
        }
      />
    
      {/* Other routes without Helmet */}
      <Route path="/comming-soon" element={<CommingSoon />} />
      <Route path="/sarkari-yojana" element={<SchemeMain />} />
      {/* <Route path="/schemes/ayushman-bharat" element={<AyushmanBharat />} /> */}



      <Route path="scheme-categories/All" element={<CategoryCardDetailAll />} />
      <Route path="scheme-categories/:scheme-category" element={<CategoryCardDetail />} />
      {/* health */}
      <Route path="scheme-health/:health-scheme" element={<SchemeDetailHealthUse />} />
      <Route path="scheme-entrepreneurship-startup/:startup-scheme" element={<SchemeDetailEntrepreneurship />} />
      <Route path="scheme-education-scholarship/:education-scheme" element={<SchemeDetailEducation />} />
      <Route path="scheme-environment-and-energy/:environment-scheme" element={<SchemeDetailEnvironment />} />
      <Route path="scheme-housing-and-urban-development/:housing-scheme" element={<SchemeDetailHousing />} />
      <Route path="scheme-employment-and-skill-development/:employment-scheme" element={<SchemeDetailEmployment />} />
      <Route path="scheme-women-and-child/:women-scheme" element={<SchemeDetailWomenAndChild />} />
      <Route path="scheme-financial/:financial-scheme" element={<SchemeDetailFinancial />} />
      <Route path="scheme-agriculture-and-rural-development/:agriculture-scheme" element={<SchemeDetailAgriculture />} />

      
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/search-exam" element={<Base />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />


{/* See more link */}
<Route path='/job/latest-job/all-links' element={<SeeMoreLinkLatestJob/>}/>
{/* <Route path='/job/admission/all-links' element={<SeeMoreLinkAdmission/>}/> */}
<Route path='/job/admit-card/all-links' element={<SeeMoreLinkAdmitCard/>}/>
<Route path='/job/result/all-links' element={<SeeMoreLinkResults/>}/>
<Route path='/job/syllabus/all-links' element={<SeeMoreLinkSyllabus/>}/>
<Route path='/job/answer-key/all-links' element={<SeeMoreLinkAnswerKey/>}/>







      {/* Admin Routes */}
      <Route path="/admin-login" element={<LoginPage />} />

      {/* Redirect to admin-dashboard if authenticated, otherwise show login */}
      <Route path="/admin-dashboard" element={isAuthenticated ? <AdminPanelBase /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/overview" element={isAuthenticated ? <ExploreOverview /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/overview/detail/:examName" element={isAuthenticated ? <ExploreOverviewDetails /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/main" element={isAuthenticated ? <ExploreMain /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/slider-links" element={isAuthenticated ? <SliderLinksAdmin/> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/easy-navigation" element={isAuthenticated ? <EasyNavigationAdmin /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/main/detail/:category/:examName" element={isAuthenticated ? <ExploreMainDetails /> : <Navigate to="/admin-login" />} />
    </Routes>
  );
};

export default BasicPagesRoutes;