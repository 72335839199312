import React from 'react';
import { useLocation } from 'react-router-dom';
import SchemeDetail from './SchemeDetail';
import {
  JanDhanYojanaData,
  DirectBenefitTransferData,
  AtalPensionYojanaData,
  PMVayaVandanaData,
  GoldMonetizationData
} from './dataDetails';

const allSchemes = [
  JanDhanYojanaData,
  DirectBenefitTransferData,
  AtalPensionYojanaData,
  PMVayaVandanaData,
  GoldMonetizationData
];

const SchemeDetailFinancial = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const currentScheme = allSchemes.find(scheme => currentUrl === scheme.schemeLink);

  if (!currentScheme) {
    return <div>Scheme not found!</div>;
  }

  return <SchemeDetail data={currentScheme} />;
};

export default SchemeDetailFinancial;