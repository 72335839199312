import React from "react";
import { Link } from "react-router-dom";

export const AdminSideBar = () => {
  

  return (
    <div>
      <aside className="border w-80 ml-2 h-[85vh]">
        <div>
          <h1 className="bg-gradient-to-br from-blue-900 to-sky-400 p-1 pl-4 text-xl font-semibold text-white">
            Structure
          </h1>
          <ul>
            <li
          
              className="pl-4 p-1 shadow-sm hover:shadow-md hover:bg-slate-50 m-1 border" >
                <Link to='/admin/explore/slider-links'>
              Slider Links
              </Link>
            </li>
            <li
              className="pl-4 p-1 shadow-sm hover:shadow-md hover:bg-slate-50 m-1 border">
              <Link to='/admin/explore/easy-navigation'>
              Easy Navigation Button
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="bg-gradient-to-br from-blue-900 to-sky-400 p-1 pl-4 text-xl font-semibold text-white mt-4">
            Explore
          </h1>
          <ul>
            <li
          
              className="pl-4 p-1 shadow-sm hover:shadow-md hover:bg-slate-50 m-1 border" >
                <Link to='/admin/explore/main'>
              Main
              </Link>
            </li>
            <li
              className="pl-4 p-1 shadow-sm hover:shadow-md hover:bg-slate-50 m-1 border">
              <Link to='/admin/explore/overview'>
              OverView
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="bg-gradient-to-br from-blue-900 to-sky-400 p-1 pl-4 text-xl font-semibold text-white mt-4">
            Yojanas
          </h1>
          <ul>
            <li
              className="pl-4 p-1 shadow-sm hover:shadow-md hover:bg-slate-50 m-1 border">
              <Link to='/admin/yojanas/main'>
              Main
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default AdminSideBar;
