import React from 'react'
import SchemeDetail from './SchemeDetail'
import { useLocation } from 'react-router-dom'
import { AyushmanBharatData,PMJanArogyaData,NationalHealthMissionData,RashtriyaSwasthyaBimaYojanaData,JananiSurakshaYojanaData } from './detailsData'
const allScheme = [
  AyushmanBharatData,
  PMJanArogyaData,
  NationalHealthMissionData,
  RashtriyaSwasthyaBimaYojanaData,
  JananiSurakshaYojanaData
]

const SchemeDetailHealthUse = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
const currentComponent = allScheme.find(scheme => currentUrl === scheme.schemeLink  )
if (!currentComponent) {

  return <div>Scheme not found!</div>;
}
  return (
    <SchemeDetail data={currentComponent}/>
  )
}
export default SchemeDetailHealthUse