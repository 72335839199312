export const PradhanMantriKaushalVikasData = {
  title: "Pradhan Mantri Kaushal Vikas Yojana",
  schemeLink: "/scheme-employment-and-skill-development/pmkvy-pradhan-mantri-kaushal-vikas",
  description: `
    **Pradhan Mantri Kaushal Vikas Yojana (PMKVY)** is a flagship scheme under the Skill India Mission. It aims to enable Indian youth to take up industry-relevant skill training that will help them in securing better livelihood opportunities.
    
    **Key Objectives**:
    - Provide skill training to youth
    - Promote sustainable livelihoods
    - Enable industry-relevant training
    - Certify skills and provide placement assistance
  `,
  applyLink: "https://pmkvyofficial.org",
  stepsToApply: [
    "Visit the official PMKVY website",
    "Register and create a profile",
    "Choose a training center and course",
    "Complete the enrollment process",
    "Attend training and assessment",
    "Receive certification and placement support"
  ],
  eligibility: [
    "Indian citizens between 15-45 years",
    "School/college dropouts",
    "Unemployed youth",
    "College/University students"
  ],
  benefits: [
    "Free skill training in selected sectors",
    "Industry-recognized certification",
    "Placement assistance",
    "Post-placement support",
    "Travel allowance during training"
  ],
  requirements: [
    "Valid ID proof (Aadhaar card)",
    "Age proof",
    "Educational certificates",
    "Bank account details"
  ],
  coveredAreas: [
    "IT and software development",
    "Healthcare and wellness",
    "Electronics and hardware",
    "Construction and infrastructure",
    "Automotive manufacturing",
    "Beauty and wellness"
  ],
  faqs:  [
    {
      question: "How can I enroll in PMKVY?",
      answer: "You can enroll by visiting the official PMKVY website or nearest training center and completing the registration process."
    },
    {
      question: "Is there any fee for training under PMKVY?",
      answer: "No, the training is provided free of cost to eligible candidates."
    },
    {
      question: "What certification will I receive?",
      answer: "You will receive a government-recognized certificate after successful completion of training and assessment."
    },
    {
      question: "What is the duration of training programs?",
      answer: "Training duration varies from 150-300 hours depending on the course selected."
    },
    {
      question: "Is there any age limit for PMKVY?",
      answer: "Yes, candidates between 15-45 years of age are eligible for the training."
    },
    {
      question: "Will I get placement assistance?",
      answer: "Yes, PMKVY provides placement assistance and post-placement support to successful candidates."
    },
    {
      question: "Can I choose my preferred training center?",
      answer: "Yes, you can select a training center from the list of approved centers in your area."
    }
  ],
  moreAboutThisScheme: `
    PMKVY is the flagship scheme under the Skill India Mission. The scheme aims to enable large numbers of Indian youth to take up industry-relevant skill training that will help them in securing a better livelihood. The scheme has been implemented to provide training free of cost to youth across the country.
  `
};

export const MGNREGAData = {
  title: "MGNREGA",
  schemeLink: "/scheme-employment-and-skill-development/mgnrega",
  description: `
    **Mahatma Gandhi National Rural Employment Guarantee Act (MGNREGA)** is a social security measure that aims to guarantee the 'right to work'. It provides a legal guarantee for 100 days of employment in every financial year to adult members of any rural household.
  `,
  applyLink: "https://nrega.nic.in",
  stepsToApply: [
    "Register at your local Gram Panchayat",
    "Obtain a job card",
    "Submit written application for work",
    "Receive work within 15 days",
    "Complete allocated work and receive wages"
  ],
  eligibility: [
    "Rural households willing to do unskilled manual work",
    "Adult members (18 years and above)",
    "Priority to women (33% reservation)",
    "Scheduled Castes and Scheduled Tribes"
  ],
  benefits: [
    "Guaranteed 100 days of employment per year",
    "Legal right to work",
    "Equal wages for men and women",
    "Unemployment allowance if work not provided",
    "Worksite facilities (shade, water, first-aid)"
  ],
  requirements: [
    "Proof of rural residence",
    "Age proof",
    "Photograph of family members",
    "Bank account details"
  ],
  coveredWorks: [
    "Water conservation and harvesting",
    "Drought proofing",
    "Irrigation works",
    "Rural connectivity",
    "Land development"
  ],
  faqs: [
    {
      question: "How many days of work are guaranteed under MGNREGA?",
      answer: "MGNREGA guarantees 100 days of employment in a financial year to every rural household."
    },
    {
      question: "What type of work is provided under MGNREGA?",
      answer: "The work involves unskilled manual labor in areas like water conservation, drought proofing, and rural connectivity."
    },
    {
      question: "How are wages paid under MGNREGA?",
      answer: "Wages are paid directly to the beneficiary's bank account on a weekly or fortnightly basis."
    },
    {
      question: "What happens if work is not provided within 15 days?",
      answer: "If work is not provided within 15 days of application, the applicant is entitled to unemployment allowance."
    },
    {
      question: "Can both husband and wife get work under MGNREGA?",
      answer: "Yes, all adult members of a household can apply for work independently."
    },
    {
      question: "Is work provided near the residence?",
      answer: "Yes, work should be provided within 5 km radius of the village. If it's beyond 5 km, extra wages must be paid."
    }
  ],
  moreAboutThisScheme: `
    MGNREGA is one of the largest work guarantee programmes in the world. The scheme aims to enhance livelihood security in rural areas by providing at least 100 days of wage employment in a financial year to every household whose adult members volunteer to do unskilled manual work.
  `
};

export const DeenDayalUpadhyayaData = {
  title: "Deen Dayal Upadhyaya Grameen Kaushalya Yojana",
  schemeLink: "/scheme-employment-and-skill-development/ddugky-deen-dayal-upadhyaya",
  description: `
    **DDU-GKY** is a placement-linked skill development program for rural youth. It aims to transform rural poor youth into an economically independent and globally relevant workforce.
  `,
  applyLink: "https://ddugky.gov.in",
  stepsToApply: [
    "Visit the DDU-GKY website",
    "Find a training center near you",
    "Submit required documents",
    "Complete the enrollment process",
    "Attend training program"
  ],
  eligibility: [
    "Rural youth aged 15-35 years",
    "Priority to marginalized groups",
    "Focus on BPL families",
    "Special consideration for women and minorities"
  ],
  benefits: [
    "Free training in selected job roles",
    "Residential and non-residential training options",
    "Post-placement support",
    "Industry-recognized certification",
    "Placement assistance"
  ],
  requirements: [
    "Rural domicile proof",
    "Age proof",
    "Educational certificates",
    "Income certificate (if applicable)"
  ],
  faqs: [
    {
      question: "Is DDU-GKY training free?",
      answer: "Yes, the training is completely free for eligible candidates."
    },
    {
      question: "What kind of jobs are available after training?",
      answer: "Jobs are available in various sectors including retail, hospitality, healthcare, and IT."
    },
    {
      question: "Is accommodation provided during training?",
      answer: "Yes, residential facilities are available for trainees from distant locations."
    },
    {
      question: "What is the minimum qualification required?",
      answer: "The minimum qualification varies by course but generally ranges from 8th pass to 12th pass."
    },
    {
      question: "Do I get any stipend during training?",
      answer: "Yes, a monthly stipend is provided to support trainees during the training period."
    },
    {
      question: "What is the duration of training programs?",
      answer: "Training duration varies from 3 months to 12 months depending on the course."
    }
  ],
  moreAboutThisScheme: `
    DDU-GKY is a key component of the National Rural Livelihood Mission (NRLM). It focuses on rural youth between 15 and 35 years of age from poor families. The program is present in 28 states and UTs across India.
  `
};

export const NationalCareerServiceData = {
  title: "National Career Service Portal",
  schemeLink: "/scheme-employment-and-skill-development/ncs-national-career-service-portal",
  description: `
    The **National Career Service (NCS)** is a one-stop solution that provides a wide array of employment and career related services to the citizens of India.
  `,
  applyLink: "https://www.ncs.gov.in",
  stepsToApply: [
    "Register on the NCS portal",
    "Create your profile",
    "Upload required documents",
    "Search and apply for jobs",
    "Attend career counseling sessions"
  ],
  eligibility: [
    "All Indian citizens",
    "Job seekers",
    "Students",
    "Employers",
    "Placement organizations"
  ],
  benefits: [
    "Free job search and matching",
    "Career counseling",
    "Skill development information",
    "Job fairs and events",
    "Industry information"
  ],
  requirements: [
    "Valid email ID",
    "Mobile number",
    "Educational certificates",
    "Work experience documents (if any)"
  ],
  faqs: [
    {
      question: "Is registration on NCS portal free?",
      answer: "Yes, registration and all services on the NCS portal are completely free."
    },
    {
      question: "What services are available on NCS?",
      answer: "NCS offers job matching, career counseling, job fairs, and skill development information."
    },
    {
      question: "How can I update my profile on NCS?",
      answer: "You can log in to your account and use the 'Edit Profile' option to update your information."
    },
    {
      question: "Are the job postings verified?",
      answer: "Yes, employers are verified before they can post jobs on the portal."
    },
    {
      question: "Can I get career guidance online?",
      answer: "Yes, NCS provides online career counseling services through registered career counselors."
    },
    {
      question: "How often are job fairs organized?",
      answer: "Job fairs are organized regularly across different locations. You can check the 'Events' section for updates."
    }
  ],
  moreAboutThisScheme: `
    The National Career Service is a mission mode project for establishing quick and efficient career-related services across the country. It provides a variety of employment and career related services to students, job seekers and employers.
  `
};

export const StartupIndiaData = {
  title: "Startup India",
  schemeLink: "/scheme-employment-and-skill-development/startup-india",
  description: `
    **Startup India** is a flagship initiative of the Government of India, intended to catalyze startup culture and build a strong and inclusive ecosystem for innovation and entrepreneurship in India.
  `,
  applyLink: "https://www.startupindia.gov.in",
  stepsToApply: [
    "Register on Startup India portal",
    "Submit required documents",
    "Get DPIIT recognition",
    "Apply for relevant schemes",
    "Access benefits and support"
  ],
  eligibility: [
    "Companies less than 10 years old",
    "Annual turnover less than ₹100 crore",
    "Working towards innovation/improvement of products/services",
    "Not formed by splitting/restructuring existing business"
  ],
  benefits: [
    "Self-certification compliance",
    "Tax benefits",
    "Easy patent filing",
    "Funding support",
    "Incubation and mentorship"
  ],
  requirements: [
    "Certificate of incorporation",
    "Business registration documents",
    "Patent/trademark details (if any)",
    "Proof of innovative concept"
  ],
  faqs: [
    {
      question: "What is DPIIT recognition?",
      answer: "DPIIT recognition is official recognition from the Department for Promotion of Industry and Internal Trade, making startups eligible for various benefits."
    },
    {
      question: "What tax benefits are available?",
      answer: "Startups can get income tax exemption for 3 years and exemption on capital gains and investments above fair market value."
    },
    {
      question: "How long does DPIIT recognition process take?",
      answer: "The recognition process typically takes 2-3 working days after submitting all required documents."
    },
    {
      question: "Can existing businesses apply for Startup India?",
      answer: "Only companies less than 10 years old and not formed by splitting existing businesses can apply."
    },
    {
      question: "What funding support is available?",
      answer: "Startups can access funding through Fund of Funds, seed funding, and various government schemes."
    },
    {
      question: "Is patent filing assistance available?",
      answer: "Yes, startups get up to 80% rebate on patent filing fees and fast-track examination."
    },
    {
      question: "How can I access mentorship support?",
      answer: "Startups can connect with mentors through the Startup India Hub and various incubation centers."
    }
  ],
  moreAboutThisScheme: `
    Startup India has emerged as a revolutionary scheme that has brought big changes in the startup culture of India. The scheme has successfully driven sustainable economic growth and generated large scale employment opportunities.
  `
};