import { FaUserTie, FaGraduationCap, FaHeartbeat, FaSeedling, FaHome, FaChild, FaPiggyBank, FaLightbulb, FaLeaf } from "react-icons/fa";
export const HealthAndWellbeing = {
    categoryLink:"/scheme-categories/health-well-being",
    icon: <FaHeartbeat />,
    title: "Health and Well-Being",
    description: "Learn about healthcare and insurance schemes.",
    schemes: [
      {
        title: "Ayushman Bharat Yojana",
        description: "Provides health insurance for low-income families.",
        link: "/scheme-health/ayushman-bharat",
      },
      {
        title: "PM Jan Arogya Yojana",
        description: "Offers free medical treatment for serious illnesses.",
        link: "/scheme-health/jan-arogya",
      },
      {
        title: "National Health Mission",
        description: "Focuses on improving healthcare infrastructure.",
        link: "/scheme-health/national-health-mission",
      },
      {
        title: "Rashtriya Swasthya Bima Yojana",
        description: "Health insurance for workers in the unorganized sector.",
        link: "/scheme-health/rashtriya-swasthya-bima",
      },
      {
        title: "Janani Suraksha Yojana",
        description: "Promotes institutional deliveries to reduce maternal mortality.",
        link: "/scheme-health/janani-suraksha",
      },
    ],
  };
  
  export const EmploymentAndSkillDevelopment = {
    categoryLink:"/scheme-categories/employment-skill-development",
    icon: <FaUserTie />,
    title: "Employment and Skill Development",
    description: "Explore schemes that promote employment and skill enhancement.",
    schemes: [
      {
        title: "Pradhan Mantri Kaushal Vikas Yojana",
        description: "Encourages skill training for youth.",
        link: "/scheme-employment-and-skill-development/pmkvy-pradhan-mantri-kaushal-vikas",
      },
      {
        title: "MGNREGA",
        description: "Provides guaranteed wage employment for rural households.",
        link: "/scheme-employment-and-skill-development/mgnrega",
      },
      {
        title: "Deen Dayal Upadhyaya Grameen Kaushalya Yojana",
        description: "Enhances skills for rural youth.",
        link: "/scheme-employment-and-skill-development/ddugky-deen-dayal-upadhyaya",
      },
      {
        title: "National Career Service Portal",
        description: "Connects job seekers with employers and training providers.",
        link: "/scheme-employment-and-skill-development/ncs-national-career-service-portal",
      },
      {
        title: "Startup India",
        description: "Promotes entrepreneurship and innovation.",
        link: "/scheme-employment-and-skill-development/startup-india",
      },
    ],
  };
  
  export const EducationAndScholarships = {
    categoryLink:"/scheme-categories/education-scholarships",
    icon: <FaGraduationCap />,
    title: "Education and Scholarships",
    description: "Discover scholarships and initiatives to improve education.",
    schemes: [
      {
        title: "Mid-Day Meal Scheme",
        description: "Provides nutritious meals to school children.",
        link: "/scheme-education-scholarship/mid-day-meal",
      },
      {
        title: "Sarva Shiksha Abhiyan",
        description: "Aims to provide universal elementary education.",
        link: "/scheme-education-scholarship/sarva-shiksha-abhiyan",
      },
      {
        title: "National Means-cum-Merit Scholarship",
        description: "Supports education for meritorious students from poor families.",
        link: "/scheme-education-scholarship/nmms-national-means-merit-scholarship",
      },
      {
        title: "Pragati Scholarship for Girls",
        description: "Encourages technical education for girls.",
        link: "/scheme-education-scholarship/pragati-scholarship",
      },
      {
        title: "Vidyalakshmi Portal",
        description: "Facilitates education loans and scholarships.",
        link: "/scheme-education-scholarship/vidyalakshmi-portal",
      },
    ],
  };
  
  export const EntrepreneurshipAndStartup = {
    categoryLink:"/scheme-categories/startups-entrepreneurship",
    icon: <FaLightbulb />,
    title: "Entrepreneurship and Startups",
    description: "Government initiatives for entrepreneurs and startups.",
    schemes: [
      {
        title: "Stand-Up India",
        description: "Provides loans for entrepreneurs from SC/ST or women.",
        link: "/scheme-entrepreneurship-startup/stand-up-india",
      },
      {
        title: "Make in India",
        description: "Encourages companies to manufacture in India.",
        link: "/scheme-entrepreneurship-startup/make-in-india",
      },
      {
        title: "Mudra Yojana",
        description: "Offers financial support to micro and small businesses.",
        link: "/scheme-entrepreneurship-startup/mudra-yojana",
      },
      {
        title: "Digital India",
        description: "Promotes technology and innovation in startups.",
        link: "/scheme-entrepreneurship-startup/digital-india",
      },
      {
        title: "Atal Innovation Mission",
        description: "Encourages innovation and entrepreneurship.",
        link: "/scheme-entrepreneurship-startup/atal-innovation",
      },
    ],
  };
  
  export const AgricultureAndRuralDevelopment = {
    categoryLink:"/scheme-categories/agriculture-rural-development",
    icon: <FaSeedling />,
    title: "Agriculture and Rural Development",
    description: "Programs to boost agriculture and rural welfare.",
    schemes: [
      {
        title: "PM-KISAN",
        description: "Provides financial assistance to farmers.",
        link: "/scheme-agriculture-and-rural-development/pm-kisan",
      },
      {
        title: "Soil Health Card Scheme",
        description: "Promotes soil testing to improve crop productivity.",
        link: "/scheme-agriculture-and-rural-development/soil-health-card",
      },
      {
        title: "Kisan Credit Card Scheme",
        description: "Offers loans to farmers for agricultural needs.",
        link: "/scheme-agriculture-and-rural-development/kisan-credit-card",
      },
      {
        title: "National Rural Livelihood Mission",
        description: "Empowers women and rural self-help groups.",
        link: "/scheme-agriculture-and-rural-development/nrlm-national-rural-livelihood",
      },
      {
        title: "Pradhan Mantri Fasal Bima Yojana",
        description: "Provides crop insurance for farmers.",
        link: "/scheme-agriculture-and-rural-development/pmfbypm-fasal-bima",
      },
    ],
  };
  
  export const WomenAndChild = {
    categoryLink:"/scheme-categories/women-child-welfare",
    icon: <FaChild />,
    title: "Women and Child Welfare",
    description: "Programs supporting women and children's health, education, and empowerment.",
    schemes: [
      {
        title: "Beti Bachao Beti Padhao",
        description: "Aims to improve child sex ratio and promote girls' education.",
        link: "/scheme-women-and-child/beti-bachao-beti-padhao",
      },
      {
        title: "Integrated Child Development Services",
        description: "Provides nutrition, immunization, and education to children.",
        link: "/scheme-women-and-child/integrated-child-development-icds",
      },
      {
        title: "Pradhan Mantri Matru Vandana Yojana",
        description: "Supports pregnant women with financial aid.",
        link: "/scheme-women-and-child/matru-vandana-yojana",
      },
      {
        title: "One Stop Centre Scheme",
        description: "Provides integrated support to women affected by violence.",
        link: "/scheme-women-and-child/one-stop-centre",
      },
      {
        title: "Sukanya Samriddhi Yojana",
        description: "Encourages savings for the education and marriage of girl children.",
        link: "/scheme-women-and-child/sukanya-samriddhi",
      },
    ],
  };
  
  export const HousingAndUrbanDevelopment = {
    categoryLink:"/scheme-categories/housing-and-urban-development",
    icon: <FaHome />,
    title: "Housing and Urban Development",
    description: "Initiatives to improve urban living and provide affordable housing.",
    schemes: [
      {
        title: "Pradhan Mantri Awas Yojana (Urban)",
        description: "Provides affordable housing in urban areas.",
        link: "/scheme-housing-and-urban-development/pmay-urban",
      },
      {
        title: "Smart Cities Mission",
        description: "Promotes sustainable and citizen-friendly urban development.",
        link: "/scheme-housing-and-urban-development/smart-cities-mission",
      },
      {
        title: "AMRUT",
        description: "Focuses on urban water supply, sewerage, and infrastructure.",
        link: "/scheme-housing-and-urban-development/amrut",
      },
      {
        title: "RERA Act",
        description: "Regulates and promotes real estate transparency and accountability.",
        link: "/scheme-housing-and-urban-development/rera-act",
      },
      {
        title: "DAY-NULM",
        description: "Enhances urban livelihoods for the urban poor.",
        link: "/scheme-housing-and-urban-development/day-nulm",
      },
    ],
  };
  
  export const FinancialInclusionAndSupport = {
    categoryLink:"/scheme-categories/finance-aid",
    icon: <FaPiggyBank />,
    title: "Financial Inclusion and Support",
    description: "Programs aimed at improving financial access and inclusion.",
    schemes: [
      {
        title: "Jan Dhan Yojana",
        description: "Provides bank accounts for all households.",
        link: "/scheme-financial/jan-dhan-yojana",
      },
      {
        title: "Direct Benefit Transfer",
        description: "Transfers subsidies directly to beneficiaries' bank accounts.",
        link: "/scheme-financial/dbt-direct-benefit-transfer",
      },
      {
        title: "Atal Pension Yojana",
        description: "Ensures pension security for workers in the unorganized sector.",
        link: "/scheme-financial/atal-pension-yojana",
      },
      {
        title: "PM Vaya Vandana Yojana",
        description: "Supports senior citizens with guaranteed pensions.",
        link: "/scheme-financial/pm-vaya-vandana",
      },
      {
        title: "Gold Monetization Scheme",
        description: "Encourages monetization of idle gold holdings.",
        link: "/scheme-financial/gold-monetization",
      },
    ],
  };
  
  export const EnvironmentAndEnergy = {
    categoryLink:"/scheme-categories/environment-and-energy",
    icon: <FaLeaf />,
    title: "Environment and Energy",
    description: "Programs for environmental protection and sustainable energy.",
    schemes: [
      {
        title: "National Solar Mission",
        description: "Promotes the use of solar energy in India.",
        link: "/scheme-environment-and-energy/national-solar-mission",
      },
      {
        title: "UJALA Scheme",
        description: "Distributes energy-efficient LED bulbs at affordable rates.",
        link: "/scheme-environment-and-energy/ujala",
      },
      {
        title: "National Electric Mobility Mission",
        description: "Encourages the use of electric vehicles.",
        link: "/scheme-environment-and-energy/national-electric-mobility",
      },
      {
        title: "CAMPA Fund",
        description: "Promotes afforestation and environmental conservation.",
        link: "/scheme-environment-and-energy/campa-fund",
      },
      {
        title: "Faster Adoption and Manufacturing of Hybrid and Electric Vehicles (FAME)",
        description: "Supports the transition to green mobility.",
        link: "/scheme-environment-and-energy/fame",
      },
    ],
  };