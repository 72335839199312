export const NationalSolarMissionData = {
  title: "National Solar Mission",
  schemeLink: "/scheme-environment-and-energy/national-solar-mission",
  description: `
    The **National Solar Mission (NSM)** is a major initiative of the Government of India to promote ecologically sustainable growth while addressing India's energy security challenge. It aims to establish India as a global leader in solar energy.

    **Key Objectives**:
    - Achieve solar power capacity of 100 GW by 2022
    - Reduce the cost of solar power generation
    - Achieve grid parity through long-term policy
    - Promote solar manufacturing capabilities
  `,
  applyLink: "https://solarrooftop.gov.in",
  stepsToApply: [
    "Visit solar rooftop portal",
    "Register and submit application",
    "Upload required documents",
    "Get technical feasibility assessment",
    "Installation and commissioning"
  ],
  eligibility: [
    "Residential building owners",
    "Commercial establishments",
    "Industrial facilities",
    "Government buildings"
  ],
  benefits: [
    "Subsidy on installation costs",
    "Reduced electricity bills",
    "Net metering facility",
    "Environmental conservation",
    "Energy independence"
  ],
  requirements: [
    "Property ownership documents",
    "Electricity bill",
    "Technical site assessment",
    "Local authority permissions",
    "Grid connection approval"
  ],
  faqs: [
    {
      question: "What subsidies are available?",
      answer: "Central Financial Assistance up to 40% for residential installations up to 3kW capacity."
    },
    {
      question: "How long does installation take?",
      answer: "Typically 2-3 months from application to commissioning."
    }
  ],
  moreAboutThisScheme: `
    The National Solar Mission has been instrumental in driving India's renewable energy growth and establishing a sustainable energy future.
  `
};

export const UJALASchemeData = {
  title: "UJALA Scheme",
  schemeLink: "/scheme-environment-and-energy/ujala",
  description: `
    **Unnat Jyoti by Affordable LEDs for All (UJALA)** is a zero-subsidy scheme that aims to promote efficient lighting, reduce electricity bills and help preserve environment.
  `,
  applyLink: "https://www.ujala.gov.in",
  stepsToApply: [
    "Visit nearest UJALA distribution center",
    "Show electricity bill and ID proof",
    "Purchase LED bulbs at subsidized rate",
    "Register purchase details",
    "Receive warranty card"
  ],
  eligibility: [
    "All domestic consumers",
    "Valid electricity connection holders",
    "Government buildings",
    "Commercial establishments"
  ],
  benefits: [
    "Energy efficient LED bulbs at low cost",
    "Reduced electricity bills",
    "Lower carbon emissions",
    "Extended bulb life",
    "Quality assured products"
  ],
  requirements: [
    "Electricity bill",
    "Identity proof",
    "Address proof",
    "Mobile number",
    "Payment for bulbs"
  ],
  faqs: [
    {
      question: "How many LED bulbs can one person buy?",
      answer: "Up to 10 LED bulbs per electricity connection."
    },
    {
      question: "What is the warranty period?",
      answer: "3 years replacement warranty on LED bulbs."
    }
  ],
  moreAboutThisScheme: `
    UJALA has successfully transformed the domestic lighting sector while generating significant energy savings and environmental benefits.
  `
};

export const NationalElectricMobilityData = {
  title: "National Electric Mobility Mission",
  schemeLink: "/scheme-environment-and-energy/national-electric-mobility",
  description: `
    The **National Electric Mobility Mission Plan (NEMMP)** aims to achieve national fuel security and provide affordable and environmentally friendly transportation.
  `,
  applyLink: "https://fame2.heavyindustry.gov.in",
  stepsToApply: [
    "Purchase eligible electric vehicle",
    "Verify FAME II compliance",
    "Submit required documents",
    "Claim applicable incentives",
    "Register vehicle with RTO"
  ],
  eligibility: [
    "Electric vehicle buyers",
    "EV manufacturers",
    "Charging infrastructure providers",
    "Public transport operators"
  ],
  benefits: [
    "Purchase incentives",
    "Tax benefits",
    "Charging infrastructure support",
    "Manufacturing incentives",
    "Research and development support"
  ],
  requirements: [
    "Vehicle purchase documents",
    "Identity proof",
    "Address proof",
    "Bank account details",
    "Registration documents"
  ],
  faqs: [
    {
      question: "What vehicles are covered?",
      answer: "Two-wheelers, three-wheelers, four-wheelers, and buses with electric powertrains."
    },
    {
      question: "How much subsidy is available?",
      answer: "Varies by vehicle category, ranging from ₹10,000 to ₹20,00,000."
    }
  ],
  moreAboutThisScheme: `
    NEMMP has been crucial in promoting electric mobility and reducing dependence on fossil fuels in the transportation sector.
  `
};

export const CAMPAFundData = {
  title: "CAMPA Fund",
  schemeLink: "/scheme-environment-and-energy/campa-fund",
  description: `
    The **Compensatory Afforestation Fund Management and Planning Authority (CAMPA)** manages funds for compensatory afforestation and environmental protection.
  `,
  applyLink: "https://campa.gov.in",
  stepsToApply: [
    "Submit project proposal",
    "Environmental impact assessment",
    "Get state authority approval",
    "Implementation plan submission",
    "Regular progress reporting"
  ],
  eligibility: [
    "State forest departments",
    "Environmental organizations",
    "Research institutions",
    "Conservation projects"
  ],
  benefits: [
    "Funding for afforestation",
    "Environmental conservation",
    "Wildlife protection",
    "Research support",
    "Capacity building"
  ],
  requirements: [
    "Detailed project report",
    "Environmental clearances",
    "Implementation timeline",
    "Budget estimation",
    "Monitoring mechanism"
  ],
  faqs: [
    {
      question: "What activities are funded?",
      answer: "Afforestation, wildlife conservation, forest protection, and related research activities."
    },
    {
      question: "How are funds allocated?",
      answer: "Based on project proposals and state-specific requirements following CAMPA guidelines."
    }
  ],
  moreAboutThisScheme: `
    CAMPA has played a vital role in ensuring environmental compensation and promoting sustainable forest management practices.
  `
};

export const FAMESchemeData = {
  title: "FAME India Scheme",
  schemeLink: "/scheme-environment-and-energy/fame",
  description: `
    **Faster Adoption and Manufacturing of (Hybrid &) Electric Vehicles (FAME)** scheme encourages faster adoption of electric and hybrid vehicles through upfront incentives and charging infrastructure.
  `,
  applyLink: "https://fame2.heavyindustry.gov.in",
  stepsToApply: [
    "Purchase FAME II approved vehicle",
    "Submit incentive claim",
    "Provide required documentation",
    "Verification by manufacturer",
    "Receive incentive amount"
  ],
  eligibility: [
    "Electric vehicle buyers",
    "Vehicle manufacturers",
    "Charging station operators",
    "State transport undertakings"
  ],
  benefits: [
    "Direct purchase incentives",
    "Charging infrastructure support",
    "Manufacturing incentives",
    "Technology development support",
    "Pilot project funding"
  ],
  requirements: [
    "Vehicle purchase invoice",
    "Identity documents",
    "Bank account details",
    "Registration certificate",
    "Insurance documents"
  ],
  faqs: [
    {
      question: "What is the incentive amount?",
      answer: "Incentives vary by vehicle category and battery capacity, ranging from ₹10,000 to ₹20,00,000."
    },
    {
      question: "Are all electric vehicles eligible?",
      answer: "Only FAME II approved vehicles from registered manufacturers are eligible."
    }
  ],
  moreAboutThisScheme: `
    FAME India Scheme has significantly accelerated the adoption of electric vehicles and development of supporting infrastructure in India.
  `
};