import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

// Importing your route components
import BasicPagesRoutes from './components/basic-pages/BasicPagesRoutes'; 
import ExploreRoutes from './components/pages/explore-all/ExploreRoutes';

function App() {
  const [isActiveHamMenu, setIsActiveHamMenu] = useState(false);

  // Scroll to top when the location changes
  const ScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth', // Optional for smooth scrolling
      });
    }, [location.key]); // Trigger on every route change
    return null;
  };

  return (
    <Router>
  
      <ScrollToTop />

      <Header isActiveHamMenu={isActiveHamMenu} setIsActiveHamMenu={setIsActiveHamMenu} />

      <div
        className={`transition-all duration-300 ease-in-out ${
          isActiveHamMenu ? 'blur-md inset-0 bg-slate-900' : ''
        }`}
        onClick={() => isActiveHamMenu && setIsActiveHamMenu(false)}
      >
        <Routes>
          {/* Main Pages Routes */}
          <Route path="/*" element={<BasicPagesRoutes />} />
          
          {/* Job-related Routes */}
          <Route path="/explore/*" element={<ExploreRoutes />} /> 
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
