export const BetiBachaoBetiPadhaoData = {
  title: "Beti Bachao Beti Padhao",
  schemeLink: "/scheme-women-and-child/beti-bachao-beti-padhao",
  description: `
    **Beti Bachao Beti Padhao (BBBP)** is a comprehensive program aimed at addressing the declining Child Sex Ratio (CSR) and related issues of empowerment of women over a life-cycle continuum. The scheme focuses on challenging gender stereotypes and promoting girls' education.

    **Key Objectives**:
    - Prevent gender-biased sex selective elimination
    - Ensure survival and protection of the girl child
    - Ensure education and participation of the girl child
  `,
  applyLink: "https://wcd.nic.in/bbbp-schemes",
  stepsToApply: [
    "Visit nearest Anganwadi center or district office",
    "Contact local women and child development office",
    "Participate in awareness programs",
    "Register for educational benefits",
    "Access healthcare services"
  ],
  eligibility: [
    "Girl children and their families",
    "Pregnant women and mothers",
    "Communities with low child sex ratio",
    "Educational institutions"
  ],
  benefits: [
    "Free education for girls",
    "Healthcare support",
    "Awareness programs",
    "Scholarships and incentives",
    "Community engagement initiatives"
  ],
  requirements: [
    "Birth certificate",
    "Aadhaar card",
    "Family income proof",
    "School enrollment details",
    "Residential proof"
  ],
  faqs: [
    {
      question: "What educational benefits are provided under BBBP?",
      answer: "The scheme provides scholarships, free education, and additional support for girl child education."
    },
    {
      question: "How can communities participate in BBBP?",
      answer: "Communities can participate through awareness programs, celebrations of girl child day, and local initiatives."
    }
  ],
  moreAboutThisScheme: `
    BBBP has made significant strides in improving the child sex ratio and promoting girl child education. The scheme works through a multi-sectoral approach involving health, education, and community participation.
  `
};

export const IntegratedChildDevelopmentData = {
  title: "Integrated Child Development Services",
  schemeLink: "/scheme-women-and-child/integrated-child-development-icds",
  description: `
    **Integrated Child Development Services (ICDS)** is India's flagship program addressing malnutrition, health, and development needs of children below 6 years, pregnant women, and lactating mothers.
  `,
  applyLink: "https://icds-wcd.nic.in/",
  stepsToApply: [
    "Visit local Anganwadi center",
    "Register with Anganwadi worker",
    "Submit required documents",
    "Attend regular health checkups",
    "Participate in nutrition programs"
  ],
  eligibility: [
    "Children below 6 years",
    "Pregnant women",
    "Lactating mothers",
    "Adolescent girls"
  ],
  benefits: [
    "Supplementary nutrition",
    "Immunization",
    "Health checkups",
    "Referral services",
    "Pre-school education"
  ],
  requirements: [
    "Child's birth certificate",
    "Mother's health card",
    "Residential proof",
    "Income certificate (if applicable)",
    "Aadhaar card"
  ],
  faqs: [
    {
      question: "What services are provided at Anganwadi centers?",
      answer: "Services include supplementary nutrition, immunization, health checkups, and pre-school education."
    },
    {
      question: "Is ICDS available in all areas?",
      answer: "Yes, ICDS services are available through Anganwadi centers across rural and urban areas."
    }
  ],
  moreAboutThisScheme: `
    ICDS has been instrumental in improving maternal and child health indicators through its comprehensive approach to early childhood care and development.
  `
};

export const MatruVandanaYojanaData = {
  title: "Pradhan Mantri Matru Vandana Yojana",
  schemeLink: "/scheme-women-and-child/matru-vandana-yojana",
  description: `
    **Pradhan Mantri Matru Vandana Yojana (PMMVY)** is a maternity benefit program providing conditional cash transfer to pregnant women and lactating mothers.
  `,
  applyLink: "https://wcd.nic.in/schemes/pradhan-mantri-matru-vandana-yojana",
  stepsToApply: [
    "Register at local Anganwadi center",
    "Submit application form",
    "Provide required documents",
    "Complete health checkups",
    "Receive installment payments"
  ],
  eligibility: [
    "Pregnant women for first live birth",
    "Lactating mothers",
    "Age 19 years and above",
    "Registered at Anganwadi center"
  ],
  benefits: [
    "Cash incentive of ₹5,000 in three installments",
    "Additional incentive under JSY",
    "Regular health checkups",
    "Nutrition counseling",
    "Institutional delivery support"
  ],
  requirements: [
    "Mother's Aadhaar card",
    "Bank account details",
    "MCP card",
    "Pregnancy registration certificate",
    "Identity proof"
  ],
  faqs: [
    {
      question: "How is the payment made under PMMVY?",
      answer: "Payment is made in three installments directly to the beneficiary's bank account."
    },
    {
      question: "Is this scheme available for second pregnancy?",
      answer: "No, PMMVY is available only for the first live birth."
    }
  ],
  moreAboutThisScheme: `
    PMMVY has significantly contributed to improving maternal health outcomes by providing financial support during pregnancy and early childcare period.
  `
};

export const OneStopCentreData = {
  title: "One Stop Centre Scheme",
  schemeLink: "/scheme-women-and-child/one-stop-centre",
  description: `
    The **One Stop Centre (OSC) Scheme** provides integrated support and assistance to women affected by violence, both in private and public spaces.
  `,
  applyLink: "https://wcd.nic.in/schemes/one-stop-centre-scheme",
  stepsToApply: [
    "Visit nearest One Stop Centre",
    "Contact through helpline number",
    "Register complaint/seek assistance",
    "Access required services",
    "Follow up support"
  ],
  eligibility: [
    "Women affected by violence",
    "Girls below 18 years",
    "Women in distress",
    "Victims of domestic violence"
  ],
  benefits: [
    "24/7 emergency response",
    "Medical assistance",
    "Police assistance",
    "Legal aid",
    "Psycho-social counseling"
  ],
  requirements: [
    "Basic identification",
    "Contact information",
    "Details of incident (if reporting violence)",
    "Medical records (if applicable)"
  ],
  faqs: [
    {
      question: "What services are available at One Stop Centres?",
      answer: "Services include medical, legal, psychological support, and temporary shelter."
    },
    {
      question: "Is there any fee for accessing OSC services?",
      answer: "No, all services at One Stop Centres are provided free of cost."
    }
  ],
  moreAboutThisScheme: `
    One Stop Centres have emerged as crucial support systems for women facing violence, providing comprehensive assistance under one roof.
  `
};

export const SukanyaSamriddhiData = {
  title: "Sukanya Samriddhi Yojana",
  schemeLink: "/scheme-women-and-child/sukanya-samriddhi",
  description: `
    **Sukanya Samriddhi Yojana (SSY)** is a small deposit scheme for girl children, providing financial security for their education and marriage expenses.
  `,
  applyLink: "https://www.indiapost.gov.in/Financial/Pages/Content/Sukanya-Samriddhi-Account.aspx",
  stepsToApply: [
    "Visit post office or authorized bank",
    "Fill account opening form",
    "Submit required documents",
    "Make initial deposit",
    "Receive account details"
  ],
  eligibility: [
    "Girl child below 10 years",
    "Only for Indian citizens",
    "Maximum two accounts per family",
    "One account per girl child"
  ],
  benefits: [
    "High interest rate",
    "Tax benefits under 80C",
    "Partial withdrawal for education",
    "Complete maturity at 21 years",
    "Government guaranteed scheme"
  ],
  requirements: [
    "Girl child's birth certificate",
    "Guardian's identity proof",
    "Address proof",
    "Photographs",
    "Initial deposit amount"
  ],
  faqs: [
    {
      question: "What is the minimum deposit amount?",
      answer: "The minimum annual deposit is ₹250 and maximum is ₹1.5 lakh."
    },
    {
      question: "When can the account be closed?",
      answer: "The account matures when the girl child turns 21 years old."
    }
  ],
  moreAboutThisScheme: `
    Sukanya Samriddhi Yojana has emerged as a popular savings instrument for securing girls' future, offering attractive interest rates and tax benefits.
  `
};