import React from 'react';
import ExploreLink from './ExploreLink';
import useFetchLinks from './useFetchLinks'; 
import config from '../../../../../config';

const ExploreList = () => {
  const categories = [
    { name: "Latest Jobs", api: "latestJob", linkPage: "/job/latest-job/all-links" },
    { name: "Results", api: "result", linkPage: "/job/result/all-links" },
    { name: "Admit Cards", api: "admitCard", linkPage: "/job/admit-card/all-links" },
    { name: "Answer Key", api: "answerKey", linkPage: "/job/answer-key/all-links" },
    { name: "Syllabus", api: "syllabus", linkPage: "/job/syllabus/all-links" },
  ];

  // Using the custom hook to fetch data for each category
  const latestJobLinks = useFetchLinks(`${config.BASE_URL}/api/explore/link/latestJob`);
  const admitCardLinks = useFetchLinks(`${config.BASE_URL}/api/explore/link/admitCard`);
  const resultLinks = useFetchLinks(`${config.BASE_URL}/api/explore/link/result`);
  const answerKeyLinks = useFetchLinks(`${config.BASE_URL}/api/explore/link/answerKey`);
  const syllabusLinks = useFetchLinks(`${config.BASE_URL}/api/explore/link/syllabus`);

  // Collect all the fetched data into an object
  const allData = {
    latestJob: latestJobLinks,
    result: resultLinks,
    admitCard: admitCardLinks,
    syllabus: syllabusLinks,
    answerKey: answerKeyLinks,
  };

  // // Check if any category is still loading
  // const isLoading = Object.values(allData).some(item => item.loading);

  // // Handle error if any category has failed to fetch data
  // const hasError = Object.values(allData).some(item => item.error);

  return (
    <>
      {/* Heading */}
      <p className="text-2xl underline text-center font-bold mt-4">EXPLORE</p>

      {/* Container */}
      <section className="flex flex-wrap justify-center sm:p-4 mt-2">
        {categories.map((category, index) => {
          const categoryData = allData[category.api];

          return (
            <div key={index} className="category-card sm:m-2">
              
              {/* Show loading animation or placeholder if data is loading */}
              {categoryData.loading ? (
                <div className="loading-effect m-6">
                  <h2 className="category-heading text-xl font-semibold text-center">{category.name}</h2>
                  <p className="text-center text-gray-500">Loading...</p>
                </div>
              ) : (
                // If data is fetched successfully, render the ExploreLink component
                <ExploreLink 
                  links={categoryData.linkData} 
                  heading={category.name} 
                  linkPage={category.linkPage} 
                />
              )}

              {/* Show error message if there's an error */}
              {categoryData.error && (
                <div className="error-message text-center text-red-500 mt-2">
                  Failed to load {category.name} links. Please try again.
                </div>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default ExploreList;
