import React from 'react';
import { Link } from 'react-router-dom';
const CategoryCard = ({ title, description, schemeCount, link, icon }) => {
  return (
    <div className="category-card border shadow-lg rounded-lg p-5 bg-gradient-to-r from-pink-50 to-green-50 hover:shadow-xl transition">
      <div className="flex items-center space-x-3 mb-4">
        <div className="text-green-600 text-3xl">{icon}</div> {/* Display the icon */}
        <h2 className="text-xl font-bold text-purple-600">{title}</h2>
      </div>
      <p className="text-gray-700 mt-2">{description}</p>
      <div className="mt-3 flex items-center justify-between">
        <span className="text-sm text-gray-500">
          {schemeCount} {schemeCount === 1 ? 'Scheme' : 'Schemes'}
        </span>
        <Link 
          to={link}
          className="text-white bg-purple-500 hover:bg-purple-600 px-4 py-2 rounded-md text-sm"
        >
          Explore
        </Link>
      </div>
    </div>
  );
};

export default CategoryCard;
