import React, { useEffect, useState } from "react";
import config from "../../../config";
import AdminPanelStructure from "../AdminPanelStructure";

const EasyNavigationAdmin = () => {
  const [alldata, setAlldata] = useState([]); // Holds fetched data
  const [loading, setLoading] = useState(false); // Tracks loading state

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${config.BASE_URL}/api/structure/easy-navigation/all` // Fixed typo in the endpoint
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
        const result = await response.json();
        setAlldata(result.data); // Assuming `result.data` contains the list
      } catch (err) {
        console.error("Error fetching data:", err);
        alert("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle input change for editable fields
  const handleInputChange = (index, field, value) => {
    const updatedData = [...alldata];
    updatedData[index][field] = value;
    setAlldata(updatedData);
  };

  // Handle API update for a specific item
  const handleUpdate = async (index) => {
    const itemToUpdate = alldata[index];
    const token = localStorage.getItem("jwt");
  
    if (!itemToUpdate || !itemToUpdate.adminLinkIdentifier) {
      alert("Invalid item data. Please refresh and try again.");
      return;
    }
  
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/admin/easy-navigation/${itemToUpdate.adminLinkIdentifier}`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            linkLabelEasyNav: itemToUpdate.linkLabelEasyNav,
            link: itemToUpdate.link,
          }),
        }
      );
  
      const responseText = await response.text();
    
  
      if (!response.ok) {
        throw new Error(
          `Error ${response.status}: ${response.statusText}\n${responseText}`
        );
      }
  
      alert("Updated successfully!");

    } catch (err) {
      console.error("Error updating item:", err);
      alert(`Failed to update the item: ${err.message}`);
    }
  };
  

  return (
    <AdminPanelStructure
      rightSidecontent={
        <div className="flex flex-col gap-4">
          {loading ? (
            <p>Loading...</p>
          ) : alldata.length === 0 ? (
            <p>No data available.</p>
          ) : (
            alldata.map((item, index) => (
              <div
                key={item.adminLinkIdentifier || index}
                className="ml-2 flex flex-col gap-2 shadow-md p-2 bg-blue-50"
              >
                <label className="text-lg font-semibold">
                  {item.adminLinkIdentifier}
                </label>
                <label className="text-md">LABEL:</label>
                <input
                  type="text"
                  value={item.linkLabelEasyNav || ""}
                  onChange={(e) =>
                    handleInputChange(index, "linkLabelEasyNav", e.target.value)
                  }
                  className="pl-4 border border-gray-300 rounded-sm focus:ring-2 focus:ring-blue-500"
                />
                <label className="text-md">LINK:</label>
                <input
                  type="text"
                  value={item.link || ""}
                  onChange={(e) =>
                    handleInputChange(index, "link", e.target.value)
                  }
                  className="pl-4 border border-gray-300 rounded-sm focus:ring-2 focus:ring-blue-500"
                />
                <button
                  className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                  onClick={() => handleUpdate(index)}
                >
                  Save
                </button>
              </div>
            ))
          )}
        </div>
      }
    />
  );
};

export default EasyNavigationAdmin;
