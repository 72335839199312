import React from 'react';
import { FaChevronDown, FaChevronRight, FaCheckCircle, FaExclamationCircle, FaFileAlt, FaExternalLinkAlt, FaChild } from 'react-icons/fa';
import { IoMdHeartEmpty } from 'react-icons/io';
import SchemeDisclaimer from '../SchemeDisclaimer';

const SchemeDetail = ({ data }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-50 via-blue-50 to-indigo-50 py-8 px-2 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Header Section */}
        <div className="relative bg-gradient-to-r from-pink-700 to-red-700 px-6 py-8 sm:px-10 sm:py-12">
          <div className="absolute inset-0 bg-grid-white/[0.1] bg-[size:16px_16px]"></div>
          <h1 className="text-3xl sm:text-4xl font-bold text-white text-center relative z-10 mb-4 flex flex-col">
            <p className="flex items-center justify-center p-4">
              <FaChild className="text-6xl" />
            </p>
            {data.title}
          </h1>
          <p className="text-pink-50 text-center max-w-2xl mx-auto text-lg">{data.description}</p>
        </div>

        <div className="p-3 sm:p-8">
          {/* Quick Actions */}
          <div className="flex flex-wrap gap-4 mb-8 justify-center">
            <a
              href={data.applyLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-pink-600 text-white rounded-lg shadow-lg hover:bg-pink-700 transition-all duration-300 transform hover:scale-105"
            >
              <FaFileAlt className="w-5 h-5 mr-2" />
              Apply Now
            </a>
            {data.applicationStatusTracking?.trackingLink && (
              <a
                href={data.applicationStatusTracking.trackingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
              >
                <FaExternalLinkAlt className="w-5 h-5 mr-2" />
                Track Application
              </a>
            )}
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            {/* Steps to Apply */}
            <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-pink-200 transition-colors">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <FaChevronRight className="w-6 h-6 text-pink-600 mr-2" />
                Steps to Apply
              </h2>
              <ol className="space-y-4">
                {data.stepsToApply.map((step, index) => (
                  <li key={index} className="flex items-start space-x-3 group">
                    <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-pink-100 text-pink-600 font-semibold group-hover:bg-pink-600 group-hover:text-white transition-colors">
                      {index + 1}
                    </span>
                    <span className="text-gray-700 group-hover:text-gray-900 transition-colors">{step}</span>
                  </li>
                ))}
              </ol>
            </section>

            {/* Eligibility & Benefits Grid */}
            <div className="grid md:grid-cols-2 gap-6">
              {/* Eligibility */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-pink-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <FaCheckCircle className="w-6 h-6 text-blue-600 mr-2" />
                  Eligibility
                </h2>
                <ul className="list-disc pl-5 space-y-2">
                  {data.eligibility.map((item, index) => (
                    <li key={index} className="text-gray-700">{item}</li>
                  ))}
                </ul>
              </section>

              {/* Benefits */}
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-pink-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                  <FaChevronDown className="w-6 h-6 text-indigo-600 mr-2" />
                  Benefits
                </h2>
                <ul className="list-disc pl-5 space-y-2">
                  {data.benefits.map((benefit, index) => (
                    <li key={index} className="text-gray-700">{benefit}</li>
                  ))}
                </ul>
              </section>
            </div>

            {/* Requirements */}
            <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-pink-200 transition-colors">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <FaExclamationCircle className="w-6 h-6 text-red-600 mr-2" />
                Requirements
              </h2>
              <ul className="list-disc pl-5 space-y-2">
                {data.requirements.map((req, index) => (
                  <li key={index} className="text-gray-700">{req}</li>
                ))}
              </ul>
            </section>

            {/* FAQs */}
            <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
              <h2 className="text-2xl font-bold text-gray-800 mb-6 flex items-center">
                <IoMdHeartEmpty className="w-6 h-6 text-orange-600 mr-2" />
                Frequently Asked Questions
              </h2>
              <div className="space-y-4">
                {data.faqs.map((faq, index) => (
                  <details key={index} className="group bg-orange-50 rounded-lg transition-all duration-300">
                    <summary className="flex items-center justify-between cursor-pointer p-4 font-medium text-gray-800">
                      {faq.question}
                      <FaChevronDown className="w-5 h-5 text-orange-600 transform group-open:rotate-180 transition-transform" />
                    </summary>
                    <div className="px-4 pb-4 text-gray-700">{faq.answer}</div>
                  </details>
                ))}
              </div>
            </section>

             <SchemeDisclaimer/>
            {/* Additional Information */}
            {data.moreAboutThisScheme && (
              <section className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:border-pink-200 transition-colors">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">More About This Scheme</h2>
                <p className="text-gray-700">{data.moreAboutThisScheme}</p>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemeDetail;
