import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import playStore from '../../assets/play-store.png';
import appStore from '../../assets/app-store.png';
import NavigationLink from './NavigationLink';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="bg-gradient-to-tr from-lime-200 to-gray-50">
      <footer className="p-10 flex flex-wrap justify-between pl-[5vw] pr-[5vw] gap-10">
        {/* Logo and About Section */}
        <div className="flex flex-col gap-5 max-w-xs">
          <h1 className="bg-gradient-to-r from-orange-500 to-green-600 bg-clip-text text-transparent font-bold text-3xl text-center cursor-pointer">
            SarkariLock.IN
          </h1>
          <p className="text-gray-700">
            We provide detailed and up-to-date information about Sarkari jobs and government schemes through in-depth research.
          </p>
          <div>
            <span className="font-semibold">Follow Us</span>
            <ul className="flex gap-3 mt-3">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL"
                  aria-label="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-500 transition duration-300"
                >
                  <FaFacebook size={24} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@sarkarilock"
                  aria-label="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-red-500 transition duration-300"
                >
                  <FaYoutube size={24} />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/@sarkarilock"
                  aria-label="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-400 transition duration-300"
                >
                  <FaXTwitter size={24} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/sarkarilock/?utm_source=qr&igsh=MW1hNDV6cW11ZjBiaA%3D%3D"
                  aria-label="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-pink-500 transition duration-300"
                >
                  <FaInstagram size={24} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Help & Contact Section */}
        <div>
          <h3 className="text-lg font-semibold text-center pb-5">HELP & CONTACT US</h3>
          <ul className="flex flex-col gap-2 text-gray-500">
            <li><NavigationLink path="/">Home</NavigationLink></li>
            <li><NavigationLink path="/about-us">About</NavigationLink></li>
            <li><NavigationLink path="/contact-us">Contact</NavigationLink></li>
            <li><NavigationLink path="/privacy-policy">Privacy & Policy</NavigationLink></li>
          </ul>
        </div>

        {/* Schemes Section */}
        <div>
          <h3 className="text-lg font-semibold text-center pb-5">SCHEMES OF GOVT.</h3>
          <ul className="flex flex-col gap-2 text-gray-500">
            <li><NavigationLink path="/scheme-health/ayushman-bharat">Ayushman Bharat Yojana</NavigationLink></li>
            <li><NavigationLink path="/scheme-employment-and-skill-development/mgnrega">MGNREGA</NavigationLink></li>
            <li><NavigationLink path="/scheme-employment-and-skill-development/startup-india">Startup India</NavigationLink></li>
            <li><NavigationLink path="/scheme-financial/jan-dhan-yojana">Jan Dhan Yojana</NavigationLink></li>
            <li><NavigationLink path="/scheme-environment-and-energy/national-solar-mission">National Solar Mission</NavigationLink></li>
            <li><NavigationLink path="/scheme-categories/all">More Schemes</NavigationLink></li>
          </ul>
        </div>

        {/* Exams Section */}
        <div>
          <h3 className="text-lg font-semibold text-center pb-5">EXAMS</h3>
          <ul className="flex flex-col gap-2 text-gray-500">
            <li><NavigationLink path="/explore/result/ssc-cgl-exam">Ssc CGL</NavigationLink></li>
            <li><NavigationLink path="/explore/job/idbi-bank-executive-sales-operations-eso">IDBI Bank</NavigationLink></li>
            <li><NavigationLink path="/explore/result/upsc-combined-medical-services">UPSC</NavigationLink></li>
            <li><NavigationLink path="/explore/answer-key/rrb-alp-recruitment">Rrb Alp</NavigationLink></li>
            <li><NavigationLink path="/explore/job/bsf-constable-gd-sports">BSF</NavigationLink></li>
          </ul>
        </div>

        {/* Install App Section */}
        <div>
          <h3 className="text-lg font-semibold text-center pb-5">INSTALL APP</h3>
          <div className="flex gap-3 justify-center">
            <Link to="/comming-soon">
              <img
                src={playStore}
                alt="Play Store"
                className="w-32 bg-black border rounded-lg p-2 hover:scale-105 transition-transform duration-300"
              />
            </Link>
            <Link to="/comming-soon">
              <img
                src={appStore}
                alt="App Store"
                className="w-32 bg-black border rounded-lg p-2 hover:scale-105 transition-transform duration-300"
              />
            </Link>
          </div>
        </div>
      </footer>
      {/* Footer Bottom */}
      <div className="bg-emerald-700 text-center text-slate-100 py-2">
        © 2024 SarkariLock.IN All rights reserved.
      </div>
    </section>
  );
};

export default Footer;
