import React from 'react'

export const SchemeDisclaimer = () => {
  return (
    <section className="bg-yellow-50 rounded-xl p-6 mt-8">
    <h2 className="text-2xl font-bold text-gray-800 mb-4">Disclaimer</h2>
    <p className="text-gray-700">
      The information provided here is based on publicly available resources. We strive to keep it up to date, but it is always advisable to consult official documentation or contact the relevant authorities to verify the details before making any decisions or taking action.
    </p>
    <p className="text-gray-700 mt-4">
      We are not liable for any discrepancies, damages, or consequences that may arise from the use of this information. Additionally, we do not take responsibility for any SMSD (Short Message Service Delivery) issues or related concerns that may arise from our services.
    </p>
  </section>
  )
}
export default SchemeDisclaimer
