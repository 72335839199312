import React from 'react';
import { useLocation } from 'react-router-dom';
import SchemeDetail from './SchemeDetail';
import {
  BetiBachaoBetiPadhaoData,
  IntegratedChildDevelopmentData,
  MatruVandanaYojanaData,
  OneStopCentreData,
  SukanyaSamriddhiData
} from './dataDetails';

const allSchemes = [
  BetiBachaoBetiPadhaoData,
  IntegratedChildDevelopmentData,
  MatruVandanaYojanaData,
  OneStopCentreData,
  SukanyaSamriddhiData
];

const SchemeDetailWomenAndChild = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const currentScheme = allSchemes.find(scheme => currentUrl === scheme.schemeLink);

  if (!currentScheme) {
    return <div>Scheme not found!</div>;
  }

  return <SchemeDetail data={currentScheme} />;
};

export default SchemeDetailWomenAndChild;