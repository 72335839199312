export const PMAYUrbanData = {
  title: "Pradhan Mantri Awas Yojana (Urban)",
  schemeLink: "/scheme-housing-and-urban-development/pmay-urban",
  description: `
    **Pradhan Mantri Awas Yojana (Urban)** is a flagship mission of the Government of India that aims to provide 'Housing for All' in urban areas. The scheme addresses the housing requirement of urban poor including slum dwellers.

    **Key Objectives**:
    - Provide affordable housing to urban poor
    - Promote inclusive and sustainable housing
    - Ensure a pucca house to every eligible urban household
    - Improve quality of life for urban poor
  `,
  applyLink: "https://pmaymis.gov.in",
  stepsToApply: [
    "Visit nearest Common Service Center or Municipal office",
    "Fill PMAY(U) application form",
    "Submit required documents",
    "Complete verification process",
    "Track application status online"
  ],
  eligibility: [
    "Urban residents belonging to EWS/LIG categories",
    "Annual income within prescribed limits",
    "No pucca house in family's name",
    "First-time home buyers"
  ],
  benefits: [
    "Credit Linked Subsidy on home loans",
    "Central assistance of ₹1-2.3 lakhs",
    "Interest subsidy up to 6.5%",
    "Affordable housing through PPP",
    "Rehabilitation of slum dwellers"
  ],
  requirements: [
    "Income proof",
    "Identity proof",
    "Address proof",
    "Bank account details",
    "Property documents (if applicable)"
  ],
  faqs: [
    {
      question: "What is the maximum income limit for PMAY(U)?",
      answer: "For EWS category: up to ₹3 lakhs per annum, for LIG category: ₹3-6 lakhs per annum."
    },
    {
      question: "What is the interest subsidy period?",
      answer: "The interest subsidy is available for a loan tenure of 20 years or actual tenure, whichever is lower."
    }
  ],
  moreAboutThisScheme: `
    PMAY(U) has transformed the urban housing sector by making affordable housing accessible to millions of urban poor through various implementation mechanisms and financial support.
  `
};

export const SmartCitiesMissionData = {
  title: "Smart Cities Mission",
  schemeLink: "/scheme-housing-and-urban-development/smart-cities-mission",
  description: `
    The **Smart Cities Mission** is an innovative initiative to drive economic growth and improve the quality of life of people by enabling local development and harnessing technology to create smart outcomes for citizens.
  `,
  applyLink: "https://smartcities.gov.in",
  stepsToApply: [
    "Cities selected through challenge process",
    "Submit Smart City proposal",
    "Develop implementation plan",
    "Create Special Purpose Vehicle",
    "Execute smart city projects"
  ],
  eligibility: [
    "Cities selected through Smart Cities challenge",
    "Urban Local Bodies",
    "State governments",
    "Special Purpose Vehicles"
  ],
  benefits: [
    "Improved urban infrastructure",
    "Smart solutions for citizens",
    "Better quality of life",
    "Sustainable environment",
    "Enhanced urban mobility"
  ],
  requirements: [
    "City-level smart city plan",
    "Implementation framework",
    "Stakeholder consultation",
    "Financial planning",
    "Technical capacity"
  ],
  faqs: [
    {
      question: "What makes a city smart under this mission?",
      answer: "A smart city uses technology and data to improve governance, infrastructure, and quality of life for citizens."
    },
    {
      question: "How are cities selected for the mission?",
      answer: "Cities are selected through a challenge process based on their smart city proposals and implementation capability."
    }
  ],
  moreAboutThisScheme: `
    The Smart Cities Mission has catalyzed the creation of sustainable and inclusive cities that provide core infrastructure and give a decent quality of life to citizens through smart solutions.
  `
};

export const AMRUTData = {
  title: "Atal Mission for Rejuvenation and Urban Transformation",
  schemeLink: "/scheme-housing-and-urban-development/amrut",
  description: `
    **AMRUT (Atal Mission for Rejuvenation and Urban Transformation)** focuses on establishing infrastructure that could ensure adequate robust sewage networks and water supply for urban transformation.
  `,
  applyLink: "https://amrut.gov.in",
  stepsToApply: [
    "City-level implementation through ULBs",
    "Prepare Service Level Improvement Plan",
    "Submit Detailed Project Report",
    "Get state approval",
    "Implement approved projects"
  ],
  eligibility: [
    "All cities and towns",
    "Priority to cities with population > 1 lakh",
    "State capitals and heritage cities",
    "Cities on main rivers"
  ],
  benefits: [
    "Universal water supply coverage",
    "Sewerage and septage management",
    "Storm water drainage",
    "Urban transport facilities",
    "Green spaces development"
  ],
  requirements: [
    "City-level plans",
    "State Annual Action Plan",
    "Detailed Project Reports",
    "Reform implementation",
    "Performance assessment"
  ],
  faqs: [
    {
      question: "What are the focus areas of AMRUT?",
      answer: "Water supply, sewerage, drainage, urban transport, and green spaces are the main focus areas."
    },
    {
      question: "How are projects funded under AMRUT?",
      answer: "Projects are funded through a mix of central assistance, state contribution, and ULB funds."
    }
  ],
  moreAboutThisScheme: `
    AMRUT has been instrumental in improving urban infrastructure and ensuring basic services to urban households through comprehensive planning and implementation.
  `
};

export const RERAActData = {
  title: "Real Estate Regulation Act",
  schemeLink: "/scheme-housing-and-urban-development/rera-act",
  description: `
    The **Real Estate (Regulation and Development) Act** establishes a regulatory framework for real estate sector, ensuring transparency and protecting homebuyers' interests.
  `,
  applyLink: "https://rera.gov.in",
  stepsToApply: [
    "Register real estate project with RERA",
    "Submit required documentation",
    "Comply with RERA guidelines",
    "Update project status regularly",
    "Address buyer grievances"
  ],
  eligibility: [
    "All real estate projects > 500 sq meters",
    "Projects with more than 8 apartments",
    "Real estate agents",
    "Property buyers"
  ],
  benefits: [
    "Transparent real estate transactions",
    "Protection of buyer interests",
    "Standardized project documentation",
    "Timely project completion",
    "Grievance redressal mechanism"
  ],
  requirements: [
    "Project registration documents",
    "Land title documents",
    "Project plans and specifications",
    "Financial details",
    "Construction timeline"
  ],
  faqs: [
    {
      question: "Is RERA registration mandatory?",
      answer: "Yes, registration is mandatory for all real estate projects above 500 sq meters or with more than 8 apartments."
    },
    {
      question: "How can buyers verify RERA compliance?",
      answer: "Buyers can check project registration details on the RERA portal of their respective state."
    }
  ],
  moreAboutThisScheme: `
    RERA has brought significant reforms in the real estate sector by ensuring transparency, accountability, and protecting the interests of homebuyers.
  `
};

export const DAYNULMData = {
  title: "Deendayal Antyodaya Yojana - National Urban Livelihoods Mission",
  schemeLink: "/scheme-housing-and-urban-development/day-nulm",
  description: `
    **DAY-NULM** aims to reduce poverty and vulnerability of urban poor households by enabling them to access gainful self-employment and skilled wage employment opportunities.
  `,
  applyLink: "https://nulm.gov.in",
  stepsToApply: [
    "Contact nearest Urban Local Body",
    "Register for skill training",
    "Apply for self-employment program",
    "Form/join Self Help Groups",
    "Access credit through banks"
  ],
  eligibility: [
    "Urban poor households",
    "Street vendors",
    "Urban homeless",
    "Self Help Groups"
  ],
  benefits: [
    "Skill development training",
    "Self-employment opportunities",
    "Access to credit",
    "Shelter for urban homeless",
    "Support to street vendors"
  ],
  requirements: [
    "Urban residence proof",
    "Income certificate",
    "Identity proof",
    "Bank account",
    "SHG membership (if applicable)"
  ],
  faqs: [
    {
      question: "What support is provided to street vendors?",
      answer: "Street vendors receive ID cards, vending zones, and access to credit and social security."
    },
    {
      question: "How can one access skill training?",
      answer: "Contact the nearest Urban Local Body or DAY-NULM center for skill training registration."
    }
  ],
  moreAboutThisScheme: `
    DAY-NULM has successfully improved urban livelihoods through its comprehensive approach to skill development, self-employment, and social mobilization.
  `
};